import Fivestarrating from '../components/Fivestarrating';
import OurProducts from '../components/OurProducts';
import Questions from "../components/Questions";
import FusionerpScription from '../components/FusionerpScription';
import Contactonehas from '../components/Contactonehas';

const Fusionerpcall = () => {
  return (
    <>			 
      <FusionerpScription />
      <Fivestarrating />
      <OurProducts />
      <Contactonehas />
      <Questions /> 
    </>
  )
}

export default Fusionerpcall