import React, { useState, forwardRef } from 'react';
import { Box, Button, Grid, TextField, Typography, MenuItem, FormControl, InputLabel, Select, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequestDemo = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

    const [country, setCountry] = React.useState('');
    const [employees, setEmployees] = React.useState('');
    const [countryOpen, setCountryOpen] = React.useState(false);
    const [employeeOpen, setEmployeeOpen] = React.useState(false);    
    const [emailError,setEmailError] = useState('');

    const [isButtonDisabled,setIsButtonDisabled] = useState(true);
    const [email,setEmail] = useState('');
    
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };
    
    const handleEmployeesChange = (event) => {
      setEmployees(event.target.value);
    };
    
    const handleCountryClose = () => {
      setCountryOpen(false);
    };
    
    const handleEmployeesClose = () => {
      setEmployeeOpen(false);
    };
    
    const handleCountryOpen = () => {
      setCountryOpen(true);
    };
    
    const handleEmployeesOpen = () => {
      setEmployeeOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const validateEmail = (email) =>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,6}$/;

        if (!emailRegex.test(email)){
            setEmailError('Invalid email address');
        }else{
            setEmailError('');
            return true;
        }
    }


    const handleEmailChange = (e) =>{
        const emailValue = e.target.value;
        setEmail(emailValue)
        const isEmailValid = validateEmail(emailValue)
        // setIsButtonDisabled(!isEmailValid)

        validateForm(isEmailValid,phoneNumber.length === 10)
    }


    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        setPhoneNumber(value);

        const isValidPhoneNumber = value.length === 10
        if(!isValidPhoneNumber){
            setIsPhoneNumberValid("Please enter the 10 digits")
        }else{
            setIsPhoneNumberValid('')
        }
        // if(value.length !== 10){
        //     setIsPhoneNumberValid('Please Enter the 10 Digits')
        //     setIsButtonDisabled(true);
        // }else{
        //     setIsPhoneNumberValid('');
        //     setIsButtonDisabled(false);
        // }

        validateForm(validateEmail(email),isValidPhoneNumber)
        
    };

    const validateForm = (email,phoneNumber) =>{
        if(!(email && phoneNumber)){
            setIsButtonDisabled(true);
        }else{
            setIsButtonDisabled(false)
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const data = new FormData(event.currentTarget);
            const formData = {
                full_name: data.get("full_name"),
                company_name: data.get("company_name"),
                mobile_no: data.get("mobile_no"),
                country: data.get("country"),
                employee_count: data.get("employee_count"),
                email_id: data.get("email_id"),
                demo_datetime: selectedDateTime ? formatDateForDatabase(selectedDateTime) : '',
            };
            // const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_demo', {

            const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_demo', {
                cache: 'no-cache',
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    full_name: formData.full_name,
                    company_name: formData.company_name,
                    country: formData.country,
                    mobile_no: formData.mobile_no,
                    no_of_employees: formData.employee_count,
                    email_id: formData.email_id,
                    demo_datetime: formData.demo_datetime,
                })
            });
    
            const result = await response.json();
            if (result.message) {
                setSnackbarMessage("Please check your email");
                setSnackbarSeverity('success');
                setSnackbarTitle('Success');
                setSnackbarOpen(true);
            }
            if (result.message && result.message.message.error) {
                let errorMessage = result.message.message.error;
                if (errorMessage === 'Email ID already exists') {
                    errorMessage = 'You already requested, please check your email.';
                    setSnackbarSeverity('warning');
                    setSnackbarTitle('Warning');
                } else {
                    setSnackbarMessage("Please check your email");
                    setSnackbarSeverity('success');
                    setSnackbarTitle('Success');
                }
                setSnackbarMessage(errorMessage);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('An error occurred. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarTitle('Error');
            setSnackbarOpen(true);
        }
    };
    
    const formatDateForDatabase = (date) => {
        const jsDate = date.toDate();
        const year = jsDate.getFullYear();
        const month = (`0${jsDate.getMonth() + 1}`).slice(-2);
        const day = (`0${jsDate.getDate()}`).slice(-2);
        const hours = (`0${jsDate.getHours()}`).slice(-2);
        const minutes = (`0${jsDate.getMinutes()}`).slice(-2);
        const seconds = (`0${jsDate.getSeconds()}`).slice(-2);
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <Grid container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
            <Grid container item xs={14} md={8} spacing={6} lg={12} sx={{ maxWidth: '1400px' }}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: 'auto',
                            width: '100%',
                            backgroundColor: '#007ee5',
                            padding: '30px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="p"
                            sx={{
                                marginBottom: '18px',
                                textAlign: 'center',
                                fontSize: '24px',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            Discover Fusionerp: Book
                            <br />
                            Your Free Demo Today
                        </Typography>
                        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                            {/* Icon 1 with Text */}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: 'white',
                                    marginBottom: '16px',
                                }}
                            >
                                <SupervisedUserCircleRoundedIcon sx={{ fontSize: '40px', marginRight: '12px' }} />
                                <Typography sx={{ fontSize: '15px' }}>
                                    <strong>Expert Guidance:</strong> Maximize Leadfeeder's potential for your business with our tips.
                                </Typography>
                            </Grid>
                            {/* Icon 2 with Text */}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: 'white',
                                    marginBottom: '16px',
                                    marginTop: '10px'
                                }}
                            >
                                <AdsClickRoundedIcon sx={{ fontSize: '40px', marginRight: '12px' }} />
                                <Typography sx={{ fontSize: '15px' }}>
                                    <strong>Personalized:</strong> Share your business goals and we’ll customize the demo just for you.
                                </Typography>
                            </Grid>
                            {/* Icon 3 with Text */}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: 'white',
                                    marginBottom: '16px',
                                    marginTop: '10px'
                                }}
                            >
                                <QuestionAnswerRoundedIcon sx={{ fontSize: '40px', marginRight: '12px' }} />
                                <Typography sx={{ fontSize: '15px' }}>
                                    <strong>Have Questions?</strong> Get all your queries answered during the demo session.
                                </Typography>
                            </Grid>
                            {/* Icon 4 with Text */}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: 'white',
                                    marginBottom: '16px',
                                    marginTop: '10px'
                                }}
                            >
                                <CurrencyExchangeRoundedIcon sx={{ fontSize: '40px', marginRight: '12px' }} />
                                <Typography sx={{ fontSize: '15px' }}>
                                    <strong>Streamlined:</strong> Experience the power of FusionERP to enhance your efficiency.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                width: '100%',
                                padding: '30px',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Full Name"
                                        name="full_name"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        label="Company Name"
                                        name="company_name"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Mobile Number"
                                        name="mobile_no"
                                        type="tel"
                                        fullWidth
                                        required
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        // error={!isPhoneNumberValid}
                                        // helperText={!isPhoneNumberValid ? 'Enter a valid 10-digit mobile number' : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email ID"
                                        name="email_id"
                                        type="email"
                                        fullWidth
                                        required
                                        onChange={handleEmailChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="country-select-label">Country</InputLabel>
                                    <Select
                                    name="country"
                                    labelId="country-select-label"
                                    id="country-select"
                                    open={countryOpen}
                                    onClose={handleCountryClose}
                                    onOpen={handleCountryOpen}
                                    value={country}
                                    label="Country"
                                    onChange={handleCountryChange}
                                    required
                                    >
                                    <MenuItem value="India">India</MenuItem>
                                    <MenuItem value="USA">USA</MenuItem>
                                    <MenuItem value="UK">UK</MenuItem>
                                    <MenuItem value="Australia">Australia</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="employees-select-label">Number of Employees</InputLabel>
                                    <Select
                                    name="employee_count"
                                    labelId="employees-select-label"
                                    id="employees-select"
                                    open={employeeOpen}
                                    onClose={handleEmployeesClose}
                                    onOpen={handleEmployeesOpen}
                                    value={employees}
                                    label="Number of Employees"
                                    onChange={handleEmployeesChange}
                                    required
                                    >
                                    <MenuItem value="1-10">1-10</MenuItem>
                                    <MenuItem value="11-50">11-50</MenuItem>
                                    <MenuItem value="51-200">51-200</MenuItem>
                                    <MenuItem value="201-500">201-500</MenuItem>
                                    <MenuItem value="1000+">1000+</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} fullWidth />}
                                            label="Preferred Demo Date and Time"
                                            value={selectedDateTime}
                                            onChange={setSelectedDateTime}
                                            required
                                            InputPr
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: '16px' }}
                                disabled={isButtonDisabled}
                            >
                                Request Demo
                            </Button>
                        </Box>
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    <strong>{snackbarTitle}:</strong> {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default RequestDemo;
