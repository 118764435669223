import { useState, useRef, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import tickmark from "../images/tickmarkonehas.png";
import backgrounimage from "../images/backgroundblueimage.png";
import dancingimage from "../images/dancingdash.png";

const features = [
  { title: "CRM", description: "Enhance your work automation with the world’s fastest Sales CRM software." },
  { title: "HRMS", description: "Never miss an opportunity for sales enablement again with the most efficient cloud-based CRM solution." },
  { title: "ERP", description: "Automate your finances, manage warehouse, HR, and projects, and lead the business world with ease." },
];

const detailedFeatures = [
  "White label domain",
  "Drip Campaign",
  "Management of Global Business",
  "Roles & user permissions",
  "Monitor Sales Target",
  "Multi-language and currency",
  "Logs and comments",
  "Kanban, Gantt, List and Calendar View",
  "Customized Web form",
  "Workflow Capability",
  "Alerts and notifications",
  "Schedule appointment",
];

const detailedFeatures2ndcard = [
  "Connect all your calendars",
  "Recurring event",
  "Automate your workflow",
  "Routing form",
  "Team pages",
  "Third party integrations",
  "Tutors booking",
  "Doctors & Therapist booking",
  "Saloon booking",
  "Book interviews with candidate",
  "All CRM features+",
  "Employee Lifecycle and Payroll Management",
];

const detailedFeatures3rdcard = [
  "Recruitment, Training and Performance Records",
  "Shift, Leave and Attendance Management",
  "Comprehensive Accounting",
  "Accounts Receivable and Payable Management",
  "Budgeting, Taxes and Profitability Insights",
  "Buying and Warehouse Management",
  "Project Management and Quality Control",
  "Analytical Reporting",
  "Timely analytics",
  "Unlimited Inbox",
];

const FeatureCard = ({ title, description, highlighted }) => (
  <Card
    sx={{
      mb: 2,
      width: '100%',
      maxWidth: '100%',
      borderRadius: '30px',
      border: `1px solid ${highlighted ? '#a3d1f7' : '#94c9f4'}`,
      backgroundColor: highlighted ? '#a3d1f7' : 'transparent',
      transition: 'background-color 0.3s, border-color 0.3s',
      boxShadow: 3,
    }}
  >
    <CardContent>
      <Typography sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const DetailedFeatureCard = ({ features }) => (
  <Card
    sx={{
      mb: 2,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      bgcolor: '#1976d2',
      color: 'white',
      borderRadius: '10px',
      height: '100%',
      overflowY: 'auto',
      '&::-webkit-scrollbar': { display: 'none' },
      scrollbarWidth: 'none',
    }}
  >
    <CardContent sx={{ padding: 0 }}>
      <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
        {features.map((feature, index) => (
          <li key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', padding: '8px' }}>
            <img src={tickmark} alt="tickmark" style={{ marginRight: '8px', width: '20px', height: '20px' }} />
            <Typography variant="body2" component="span" sx={{ fontWeight: '800', fontSize: '1rem' }}>
              {feature}
            </Typography>
          </li>          
        ))}
      </ul>
    </CardContent>
  </Card>
);

const FeaturesSection = () => {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const detailedFeatureRef = useRef(null);

  const detailedFeatureSections = [
    detailedFeatures,
    detailedFeatures2ndcard,
    detailedFeatures3rdcard,
  ];

  useEffect(() => {
    const updateHighlightedIndex = () => {
      if (detailedFeatureRef.current) {
        const scrollTop = detailedFeatureRef.current.scrollTop;
        const totalHeight = detailedFeatureRef.current.scrollHeight;
        
        const sectionHeight = totalHeight / detailedFeatureSections.length;

        let newIndex = Math.floor(scrollTop / sectionHeight);
        newIndex = Math.min(newIndex, detailedFeatureSections.length - 1); 

        setHighlightedIndex(newIndex);
      }
    };

    const handleScroll = () => {
      updateHighlightedIndex();
    };

    const element = detailedFeatureRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, [detailedFeatureSections.length]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        bgcolor: 'background.default',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
        width: '100%', 
        boxSizing: 'border-box', 
      }}
    >
      <Grid container spacing={2} sx={{ flex: 1, flexDirection: { lg: 'column' }, overflowY: 'auto' }}>
        {features.map((feature, index) => (
          <Grid item xs={12} lg={12} key={index}>
            <FeatureCard
              title={feature.title}
              description={feature.description}
              highlighted={index === highlightedIndex}
            />
            <Box sx={{ display: { xs: 'block', lg: 'none' }, mt: 2 }}>
              <DetailedFeatureCard features={detailedFeatureSections[index]} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        ref={detailedFeatureRef}
        sx={{
          flex: 2,
          display: { xs: 'none', lg: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          overflowY: 'auto',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          paddingLeft: { xs: 0, lg: 2 },
        }}
      >
        <Grid container spacing={2}>
          {detailedFeatureSections.map((section, index) => (
            <Grid item xs={12} key={index}>
              <DetailedFeatureCard features={section} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const OurProducts = () => {
  return (
    <Box
      sx={{
        p: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', 
        bgcolor: 'background.default',
        mt: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start', 
          width: '100%',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: { xs: 500, sm: 700, md: 1000 },
            mb: 0.5, 
          }}
        >
          Our Products
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: { xs: 500, sm: 700, md: 1000 },
            mb: 2, 
            zIndex:1
          }}
        >
          Features
        </Typography>
        
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${backgrounimage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FeaturesSection />
        </Box>
      </Box>
    </Box>
  );
};

export default OurProducts;
