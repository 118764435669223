import { Box, Typography, Rating } from '@mui/material';
import capeture from "../images/onehas_capterra-logo.svg";
import software from "../images/software_newsft.png";
import growd from "../images/onehas_g2crowd-logo.svg";
import getapp from "../images/onehas_getapp-logo.svg";
import lock from "../images/lock.svg";
import stripe from "../images/Stripeone.svg";

const Fivestarrating = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <img
          src={lock}
          alt="secure payment"
          style={{ maxWidth: '40px', marginRight: '8px', marginBottom: '1px' }}
        />
        <Typography
          variant="body1"
          sx={{ 
            fontSize: '13px', 
            fontWeight: '100',
            color: 'grey', 
            display: 'flex',
            alignItems: 'center'
          }}
        >
          100% secure payment, powered by 
          <img
            src={stripe}
            alt="stripe"
            style={{ 
              maxWidth: '80px', 
              marginLeft: '8px',
              marginBottom: '-2px'
            }}
          />
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '32px', sm: '48px', md: '64px', lg: '124px' },
        }}
      >
        {[{ img: capeture }, { img: software }, { img: growd }, { img: getapp }].map((item, index) => (
          <Box key={index} sx={{ textAlign: 'center', m: 2, flex: '1 1 auto' }}>
            <img
              src={item.img}
              alt={`rating-${index}`}
              style={{ maxWidth: '100px', marginBottom: '8px', width: '100%' }}
            />
            <Typography component="legend" sx={{ visibility: 'hidden' }}>{item.label}</Typography>
            <Rating name={`rating-${index}`} value={4.5} precision={0.5} readOnly  
            
            sx={{ fontSize: { xs: '1rem', sm: '1.3rem' } }} />
            <Typography component="legend" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' } }}>4.5/5 Rating</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Fivestarrating;
