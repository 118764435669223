import React, { useState } from 'react';
import { Typography, Button, Container, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import Fusionerpcall from '../Routes/Fusionerpcall';
import Fusionerpchat from '../Routes/Fusionerpchat';
import Fusionerpcrm from '../Routes/Fusionerpcrm'

const PricingSection = styled('section')(({ theme }) => ({
  textAlign: 'center',
  padding: '50px 20px',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 10px',
  },
}));

const ProductOptions = styled('div')(({ theme }) => ({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ProductButton = styled(Button)(({ isSelected }) => ({
  fontWeight: 500,
  lineHeight: '1.2',
  fontFamily: 'Poppins, sans-serif',
  color: isSelected ? '#fff' : 'gray',
  margin: '5px',
  borderRadius: '50px',
  backgroundColor: isSelected ? '#007ee5' : 'transparent',
  border: isSelected ? '2px solid #007ee5' : 'none',
  height: '38px',
  minWidth: '120px',
  '&:hover': {
    backgroundColor: isSelected ? '#005bb5' : 'transparent',
    borderColor: isSelected ? '#005bb5' : 'transparent',
  },
}));

const PricingPage = () => {
  const [selectedProduct, setSelectedProduct] = useState('Fusionerp Call');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <Container>
      <PricingSection>
        <Typography
          sx={{
            fontSize: isSmallScreen ? '24px' : '40px',
            color: '#007ee5',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '700',
            lineHeight: '2',
            letterSpacing: '-1.5px',
          }}
        >
          Choose a product and plan that caters to your need
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: 'rgba(15,2,41,.4)',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '700',
            fontSize: isSmallScreen ? '10px' : '12px',
          }}
        >
          Subscribe for any plan and get <span style={{ color: '#007bff', fontWeight: '600' }}>14 days free trial</span>
        </Typography>

        <ProductOptions>
          <ProductButton
            isSelected={selectedProduct === 'Fusionerp Call'}
            onClick={() => handleProductClick('Fusionerp Call')}
          >
            HRMS
          </ProductButton>
          <ProductButton
            isSelected={selectedProduct === 'Fusionerp CRM'}
            onClick={() => handleProductClick('Fusionerp CRM')}
          >
            CRM
          </ProductButton>
          <ProductButton
            isSelected={selectedProduct === 'Fusionerpcrm Chat'}
            onClick={() => handleProductClick('Fusionerpcrm Chat')}
          >
            ERP
          </ProductButton>
        </ProductOptions>
      </PricingSection>

      <div>
        {selectedProduct === 'Fusionerp Call' && <Fusionerpcall />}
        {selectedProduct === 'Fusionerp CRM' && <Fusionerpchat />}
        {selectedProduct === 'Fusionerpcrm Chat' && <Fusionerpcrm />}
      </div>
    </Container>
  );
};

export default PricingPage;
