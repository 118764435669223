import React from 'react';
import { Box, Typography, Button } from "@mui/material";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import Footer from './Footerhcm';
import { useNavigate } from 'react-router-dom';

const TrialSection = () => {
  const navigate = useNavigate();	
  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        padding: { xs: '20px', md: '40px' },
        backgroundColor: '#005bb5',
        width: { xs: '90vw', sm: '95vw', md: '98vw', lg: '98vw' },
        textAlign: { xs: 'center', md: 'left' },
        color: 'white',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: '600px',
          margin: { xs: '0 auto', md: '0' },
          textAlign: { xs: 'center', md: 'left' },
          padding: { xs: '0 20px', md: '0 40px' },
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontWeight: 700,
            mb: 2,
            fontSize: { xs: '24px', md: '30px' },
          }}
        >
          Try CRM Free for 14 days
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: 'white',
            mb: 3,
            fontSize: { xs: '18px', md: '22px' },
            fontWeight: 500,
          }}
        >
          No contracts, no credit card.
        </Typography>
        <Button
          variant="contained"
          color="primary"

          target="_blank"
          sx={{
            fontSize: { xs: '12px', md: '14px' },
            fontWeight: 600,
            marginTop: '20px',
            backgroundColor: 'white',
            color: '#005bb5',
            padding: '12px 24px',
            borderRadius: '50px',
            transition: 'transform 0.3s ease',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'translateY(-2px)',
            },
          }}
          onClick={() => navigate('/signup')} 
        >
          Get Started with your Trial
        </Button>
      </Box>

      <Box
        sx={{
          flex: { xs: '1', md: '0.5' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          marginTop: { xs: '30px', md: '30px' },
          padding: { xs: '0 20px', md: '0 40px' },
          maxWidth: '400px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CheckSharpIcon sx={{ color: 'white', marginRight: '10px', fontSize: { xs: '18px', md: '16px' } }} />
          <Typography variant="body1" sx={{ fontSize: { xs: '14px', md: '12px' } }}>
            The first 14 days are on us
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CheckSharpIcon sx={{ color: 'white', marginRight: '10px', fontSize: { xs: '18px', md: '16px' } }} />
          <Typography variant="body1" sx={{ fontSize: { xs: '14px', md: '12px' } }}>
            ERP setup is chargeable
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckSharpIcon sx={{ color: 'white', marginRight: '10px', fontSize: { xs: '18px', md: '16px' } }} />
          <Typography variant="body1" sx={{ fontSize: { xs: '14px', md: '12px' } }}>
            Access to our premium tools
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box>
      <Footer />
    </Box>
    </>
  );
};

export default TrialSection;
