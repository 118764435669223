import Fivestarrating from '../components/Fivestarrating';
import OurProducts from '../components/OurProducts';
import Questions from "../components/Questions";
import Contactonehas from '../components/Contactonehas';
import FusionerpCrmScription from '../components/FusionerpCrmScription';


const Onehascrm = () => {
  return (
    <>			 
      <FusionerpCrmScription />
      <Fivestarrating />
      <OurProducts />
      <Contactonehas />
      <Questions />    
    </>
  )
}

export default Onehascrm