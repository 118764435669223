import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import background from "../images/backgroundtrustedimage.png";
import saveicon from "../images/savecompany.png";
import business from "../images/businness.png";
import print from "../images/The Print.png";
import week from "../images/The-Week-Logo-Big.png";
import yahoo from "../images/yahoofinancev2.png";
import cioreview from "../images/Coreview.png";
import first from "../images/firstapp.png";
import second from "../images/secondimage.svg";
import third from "../images/thirdimage.png";
import fourth from "../images/fourth.svg";
import five from "../images/fivth.svg";
import sixth from "../images/sixth.svg";
import seven from "../images/seven.svg";
import eight from "../images/eight.png";
import ninth from "../images/ninth.png";
import TrialSection from './TrialSection';

const questions = [
  {
    question: "Is there a limit to sending emails?",
    answers: [
      "Yes, if you use  email account/ SMTP there is a limit of 500 emails per month.",
      "With OneCRM you can easily be configured with any of your email accounts using SMTP and using this you can send Unlimited Emails.",
      "There is no limit to emails if you send emails using your own account."
    ]
  },
  {
    question: "Is there a limit to the storage",
    answers: [
      "Yes, there is a storage limit of 25GB per site. The storage limit can be extended with an additional cost of $30 / 25 GB / Month"
    ]
  },
  {
    question: "Do you have any partnership programs",
    answers: [
      "Yes, we do. To become our partner visit at partner.fusionerp.in"
    ]
  },
  {
    question: "Will I get access to all future updates and features",
    answers: [
      "Of course! If you purchase our deal now, you will get all future updates and features without any additional costs"
    ]
  },
  {
    question: "What does 'unlimited users' mean",
    answers: [
      "You can add any number of users to your plan without paying for additional users."
    ]
  }
];

const Questions = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ 
        textAlign: 'center', 
        padding: { xs: '40px 20px', sm: '40px 20px' }, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
      }}>
        <Typography sx={{ 
          fontSize: { xs: '24px', sm: '30px', md: '35px', lg: '50px' }, 
          fontWeight: 700 
        }}>
          <Box component="span" sx={{ marginRight: '5px' }}>
            All your
          </Box>
          <Box component="span" sx={{ color: '#0080e5', marginX: '5px' }}>
            questions
          </Box>
          <Box component="span" sx={{ marginLeft: '5px' }}>
            answered <span style={{ color: '#0080e5', marginRight: '10px' }}>.</span>
          </Box>
        </Typography>
        {questions.map((item, index) => (
          <Accordion
            key={index}
            sx={{
              width: { xs: '90%', sm: '80%', md: '80%', lg: '1006px' },
              borderRadius: '10px',
              overflow: 'hidden',
              marginTop: '15px',
              marginBottom: '15px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              sx={{
                backgroundColor: 'rgba(0, 128, 229, 0.9)',
                color: 'white',
                borderRadius: '10px 10px 0 0',
                padding: '10px 15px',
              }}
            >
              <Typography sx={{ fontSize: { xs: '12px', md: '14px', lg: '16px' }, fontWeight: 300 }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: 'rgba(245, 245, 245, 0.7)',
                maxHeight: '150px',
                overflowY: 'auto',
                textAlign: 'left',
                borderRadius: '0 0 10px 10px',
                border: '1px solid #005bb5',
                padding: { xs: '10px', md: '15px' },
              }}
            >
              {item.answers.map((answer, answerIndex) => (
                <Typography key={answerIndex} variant="body1" sx={{ marginBottom: '10px', fontSize: { xs: '12px', md: '14px' } }}>
                  {answer}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>


      {/* <Box
      sx={{
        width: { xs: '90vw', sm: '95vw', md: '98vw', lg: '98vw' },
        backgroundColor: '#005bb5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: { xs: 'cover', md: '700px' }, 
        backgroundAttachment: 'fixed',
        padding: { xs: '30px', sm: '50px', md: '70px', lg: '110px' },
        marginBottom: '20px',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '90%', 
        }}
      >
        <img
          src={saveicon}
          alt="saveicon"
          style={{
            maxWidth: '60px', 
            height: 'auto',
            marginRight: '10px', 
          }}
        />
        <Typography variant='h5' sx={{ marginBottom: '10px' }}>
          Trusted by
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '28px', md: '32px', lg: '38px' },
            fontWeight: 700,
            color: 'white', 
          }}
        >
          5000+ Companies
        </Typography>
      </Box>
    </Box> */}

      {/* <Box
        
        sx={{
          width: { xs: '90%', md: '87%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '10px',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          marginTop: '-20px',
        }}
      >
        <Typography sx={{ fontWeight: 800, textAlign: 'center', padding: '20px', fontSize: { xs: '16px', md: '32px' } }}>
          Featured in
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
          <img src={business} alt="business" style={{ maxWidth: '125px', height: 'auto', margin: '10px' }} />
          <img src={print} alt="The Print" style={{ maxWidth: '125px', height: 'auto', margin: '10px' }} />
          <img src={week} alt="The Week" style={{ maxWidth: '125px', height: 'auto', margin: '10px' }} />
          <img src={yahoo} alt="Yahoo Finance" style={{ maxWidth: '125px', height: 'auto', margin: '10px' }} />
          <img src={cioreview} alt="CIO Review" style={{ maxWidth: '125px', height: 'auto', margin: '10px' }} />
        </Box>
      </Box>

      <Box sx={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>
        <Typography sx={{ marginBottom: '30px', letterSpacing: '1px', fontSize: { xs: '24px', md: '30px' }, fontWeight: 600 }}>
          Awards and Recognition
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
          <img src={first} alt="first" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={second} alt="second" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={third} alt="third" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={fourth} alt="fourth" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={five} alt="five" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={sixth} alt="sixth" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={seven} alt="seven" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={eight} alt="eight" style={{ width: '80px', height: '80px', margin: '30px' }} />
          <img src={ninth} alt="ninth" style={{ width: '80px', height: '80px', margin: '30px' }} />
        </Box>
      </Box> */}
        
      <TrialSection />
    </Box>
  );
};

export default Questions;
