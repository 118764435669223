import React, { useEffect } from "react";
import { useState } from 'react';

import { Typography, Container, Button, Box, Grid, Icon } from "@mui/material";
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import Footer from "./Footerhcm";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BarChartIcon from '@mui/icons-material/BarChart';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import UpdateIcon from '@mui/icons-material/Update';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BadgeIcon from '@mui/icons-material/Badge';
import SecurityIcon from '@mui/icons-material/Security';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SpeedIcon from '@mui/icons-material/Speed'; // Alternatively, use a custom speedometer icon if needed.
import CategoryIcon from '@mui/icons-material/Category';
import SyncIcon from '@mui/icons-material/Sync';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CreateIcon from '@mui/icons-material/Create';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PieChartIcon from '@mui/icons-material/PieChart';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import hs1 from '../images/hrms-sub-1.jpg';
import hs2 from '../images/hrms-sub-2.jpg';
import hs3 from '../images/hrms-sub-3.jpg';
import hs4 from '../images/hrms-sub-4.jpg';
import hs5 from '../images/hrms-sub-5.jpg';
import hs6 from '../images/hrms-sub-6.jpg';
import hr7 from '../images/hrms-sub-7.jpg';
import hr8 from '../images/hrms-sub-8.jpg';
import hr9 from '../images/hrms-sub-9.jpg';
import './HRMS.css';
import ciphercode from '../images/logo-png.png';
import hrms from '../images/hrms.gif';




const content = [
  {
    title: "Streamline Your Recruitment Process ",
    description: "Efficiently manage the recruitment journey with our Applicant Tracking System. Track candidates through various stages, automate application management, and streamline communications to enhance your hiring process.",
    image: <PersonSearchIcon />
  },
  {
    title: "Post Jobs and Track Applications Effortlessly",
    description: "Easily create and manage job postings. Integrate with your website or external job boards to attract a wide pool of applicants, and track and evaluate incoming applications seamlessly.",
    image: <TrackChangesIcon />
  },
  {
    title: "Simplify Interview Coordination",
    description: "Organize and schedule interviews seamlessly. Utilize automated notifications and reminders to coordinate between candidates and interviewers, ensuring a smooth and efficient interview process.",
    image: <ScheduleIcon />
  },
  {
    title: "Efficient Offer Creation and Management",
    description: "Simplify the process of creating and managing job offers. Track the creation and distribution of digital job offers from a centralized dashboard, reducing paperwork and speeding up your hiring timelines.",
    image: <AssignmentIcon />
  },
  {
    title: "Streamlined Authorization Process",
    description: "Ensure a smooth candidate authorization process with automated workflows and clear tracking. This feature helps you quickly verify and authorize candidates, ensuring compliance and consistency.",
    image: <VerifiedUserIcon />
  },
  {
    title: "Smooth and Efficient Onboarding",
    description: "Welcome new hires with a structured and efficient onboarding process. Automate paperwork, set up tasks, and guide new employees through their initial steps to ensure a seamless transition into your team.",
    image: <PersonAddIcon />
  },

]
const content2 = [
  {
    title: "Plan Leaves with Ease and Precision",
    description: "Simplify leave management with our comprehensive Leave Planning feature. Easily schedule, track, and manage employee leaves while ensuring optimal workforce availability. Stay ahead with real-time insights into leave balances and plan replacements seamlessly.",
    image: <EventNoteIcon />
  },
  {
    title: "Stay Informed with a Centralized Holiday Calendar",
    description: "Keep everyone in sync with a unified Holiday Calendar. Automatically populate public and company-specific holidays, ensuring employees are aware of important dates. Customize the calendar based on regions or departments, making it easy to manage diverse teams.",
    image: <CalendarTodayIcon />
  },
  {
    title: "Automate Leave Accruals and Carryovers",
    description: "Effortlessly manage leave accruals and carryovers with automated calculations. Define accrual rules based on employee policies and ensure accurate leave balances are maintained. This feature supports transparent and fair leave management, enhancing employee satisfaction.",
    image: <UpdateIcon />
  },
  {
    title: "Streamline Leave Approvals with Custom Workflows",
    description: "Enhance efficiency with a customizable Approval Workflow for leave requests. Set up multi-level approval hierarchies, automate notifications, and track the status of leave requests in real-time. Empower managers to approve or reject leaves with a few clicks, ensuring smooth operations.",
    image: <AltRouteIcon />
  },
  {
    title: "Manage Leave Requests On the Go",
    description: "Whether you're in the office or on the move, easily submit, approve, or track leave applications. Enjoy the convenience of full access to leave management features from anywhere, ensuring seamless HR operations at your fingertips.",
    image: <RequestPageIcon />
  },
  {
    title: "Unlock Valuable Insights with Comprehensive Reports & Analytics",
    description: "Gain a deeper understanding of your HR metrics with our detailed reports and analytics. Track leave trends, employee attendance, and more, all from a single dashboard. Use data-driven insights to make informed decisions and optimize your HR strategies.",
    image: <BarChartIcon />
  },

]
const content1 = [
  {
    title: "Track Employee Attendance with Location Precision",
    description: "The Geotagging feature allows you to capture the exact location of employees when they clock in or out. This tool is especially useful for businesses with field staff or remote employees, ensuring that attendance records are accurate and reliable.",
    image: <LocationOnIcon />
  },
  {
    title: "Enhance Accuracy with Biometric Attendance Solutions",
    description: "Biometric Attendance Tracking integrates with biometric devices such as fingerprint scanners or facial recognition systems to record employee attendance. This feature ensures a high level of accuracy and security by verifying employee identity through unique biometric data.",
    image: <FingerprintIcon />
  },
  {
    title: "Manage Attendance Anytime, Anywhere with Mobile Accessibility",
    description: "The Mobile First feature prioritizes mobile accessibility, enabling employees to check in and out, view their attendance records, and request leave directly from their smartphones.",
    image: <MobileFriendlyIcon />
  },
  {
    title: "Gain Insights with Comprehensive Attendance Reports",
    description: "The Reports & Analytics feature offers detailed insights into attendance patterns, late arrivals, absenteeism, and more. It provides customizable reports and visualizations that help managers understand workforce trends and make data-driven decisions.",
    image: <AssessmentIcon />
  },
  {
    title: "Seamlessly Integrate Attendance Data with Payroll",
    description: "The Payroll Integration feature ensures a smooth flow of attendance data into the payroll system. It automates the calculation of working hours, overtime, and leaves, ensuring accurate payroll processing. This integration minimizes manual data entry, reduces errors",
    image: <HowToRegIcon />
  },


]
const content3 = [
  {
    title: "Onboarding",
    description: "Welcome new hires with a smooth and efficient onboarding experience. Our Onboarding feature automates the entire process, from documentation to orientation scheduling.",
    image: <PersonAddIcon />
  },
  {
    title: "Organizational Hierarchy",
    description: "Easily navigate and manage your company's organizational hierarchy. Our tool provides a clear, visual representation of reporting lines and departmental structures. This feature helps in defining roles, responsibilities, and relationships, ensuring transparency and efficient communication within the organization.",
    image: <AccountTreeIcon />
  },
  {
    title: "Employee Profile",
    description: "Maintain detailed and up-to-date records of each employee with the Employee Profile feature. Store personal details, job history, skills, and qualifications in one secure location.",
    image: <BadgeIcon />
  },
  {
    title: "Digital Signature",
    description: "Facilitate secure and efficient document approvals with the Digital Signature feature. This tool allows employees and management to sign documents electronically, reducing paperwork and speeding up administrative processes. ",
    image: <SecurityIcon />
  },
  {
    title: "Employee Self Service Portal",
    description: "The Employee Self Service Portal gives your team the tools they need to manage their own HR-related tasks. Employees can easily access and update their personal information, view payslips, apply for leave, and participate in training programs.",
    image: <AccountCircleIcon />
  },
  {
    title: "Role-Based Access",
    description: "Ensure data security and streamline user experience with Role-Based Access. This feature allows you to define specific permissions for each role within your organization, ensuring that employees have access only to the information and functionalities pertinent to their responsibilities.",
    image: <GroupIcon />
  },

]
const content4 = [
  {
    title: "Salary Structure",
    description: "Create detailed salary structures tailored to different roles and levels within your organization. The Salary Structure feature allows for easy configuration of basic pay, allowances, deductions, and benefits. Customize salary components to reflect company policies and employee agreements, ensuring transparency and consistency in compensation management.",
    image: <AttachMoneyIcon />
  },
  {
    title: "Pay Groups",
    description: "Streamline your payroll processing by categorizing employees into Pay Groups based on similar payment cycles, benefits, and allowances. This feature simplifies the administration of payroll, ensuring accurate and timely disbursement of salaries.",
    image: <FormatListBulletedIcon />
  },
  {
    title: "Flexi Benefit Plan",
    description: "Enhance employee satisfaction with the Flexi Benefit Plan feature, which allows employees to choose from a variety of benefits according to their preferences. From health insurance to meal vouchers, this customizable plan offers flexibility in selecting benefits, making it easier to cater to diverse employee needs while maintaining cost control.",
    image: <AdjustIcon />
  },
  {
    title: "Tax Compliance",
    description: "Stay compliant with local tax laws and regulations using the Tax Compliance feature. Automatically calculate and deduct taxes based on employee earnings, benefits, and deductions. This feature helps avoid penalties by ensuring accurate reporting and timely submission of taxes, supporting seamless compliance management.",
    image: <CheckCircleIcon />
  },
  {
    title: "Loans",
    description: "The Loans feature simplifies the administration of employee loans and advances. Track loan applications, approvals, disbursements, and repayments efficiently. Set up loan types, repayment schedules, and interest rates, and maintain clear records to ensure transparency and accountability in financial transactions with employees.",
    image: <CreditCardIcon />
  },
  {
    title: "Pay Slips",
    description: "Provide employees with comprehensive pay slips detailing their earnings, deductions, and net pay. The Pay Slips feature automates the generation and distribution of pay slips, ensuring accuracy and confidentiality.",
    image: <ReceiptIcon />
  },

]
const content5 = [
  {
    title: "Vehicle Mileage",
    description: "The Vehicle Mileage feature enables employees to log miles driven for business purposes accurately. This tool calculates reimbursements based on predefined rates, ensuring fair and consistent compensation. With detailed tracking and reporting, it simplifies the process of managing mileage expenses, providing transparency and accountability in expense claims.",
    image: <SpeedIcon />
  },
  {
    title: "Location Based Per Diem",
    description: "Location Based Per Diem allows for the automated calculation of daily allowances based on the travel destination. This feature considers factors such as the cost of living in different locations, ensuring employees receive appropriate per diem rates.",
    image: <LocationOnIcon />
  },
  {
    title: "Limit Category Wise Expense",
    description: "The Limit Category Wise Expense feature helps control spending by setting predefined limits for various expense categories, such as travel, meals, and accommodations. This feature ensures that expenses stay within budget and adhere to company policies. ",
    image: <CategoryIcon />
  },
  {
    title: "Approval Flow",
    description: "The Approval Flow feature automates the expense approval process, allowing for the configuration of multi-level approval workflows based on the company's hierarchy. It ensures that all expenses are reviewed and approved according to established guidelines, enhancing oversight and compliance.",
    image: <SyncIcon />
  },
  {
    title: "Advance Request",
    description: "Advance Request allows employees to request advances for anticipated expenses. This feature simplifies the process of obtaining funds for travel, projects, or other business-related activities. It includes tracking and reconciliation capabilities, ensuring that advances are accounted for and properly managed.",
    image: <RequestPageIcon />
  },
  {
    title: "Travel Desk",
    description: "The Travel Desk feature provides a centralized platform for managing all aspects of business travel, from booking flights and accommodations to organizing transportation. It ensures that travel arrangements comply with company policies and negotiated rates, offering convenience and cost savings.",
    image: <TravelExploreIcon />
  },

]
const content6 = [
  {
    title: "Reward and Recognition",
    description: "The Reward and Recognition feature helps you acknowledge and celebrate the accomplishments of your employees. Whether it's through awards, bonuses, or public recognition, this tool allows you to create and manage programs that highlight exceptional performance and contributions.",
    image: <EmojiEventsIcon />
  },
  {
    title: "Nexus",
    description: "Nexus serves as a central hub for communication and collaboration within your organization. This feature facilitates team building and enhances engagement by providing a platform for sharing updates, announcements, and achievements.",
    image: <CenterFocusStrongIcon />
  },
  {
    title: "Surveys",
    description: "The Surveys feature enables you to create and distribute surveys to collect employee feedback on various topics, such as job satisfaction, workplace environment, and organizational policies. This tool provides valuable insights into employee sentiment and helps identify areas for improvement.",
    image: <FeedbackIcon />
  },

]
const content7 = [
  {
    title: "Course Creation",
    description: "Design comprehensive training programs with our intuitive Course Creation feature. Easily develop and customize training modules tailored to your organization's needs. Streamline the process of creating diverse courses, ensuring they are relevant and engaging for all departments.",
    image: <CreateIcon />
  },
  {
    title: "Learning Suggestions",
    description: "Empower your employees with personalized learning paths. Our Learning Suggestions feature uses advanced algorithms to recommend courses based on individual career goals and past learning history. ",
    image: <LightbulbIcon />
  },
  {
    title: "Batches",
    description: "Organize and manage course batches with ease. Schedule training sessions, track attendance, and monitor progress for different groups of learners. Our batch management feature ensures that each course runs smoothly, with the ability to accommodate varying schedules and learning paces.",
    image: <GroupIcon />
  },
  {
    title: "Certification",
    description: "Provide formal recognition for your employees' accomplishments. With our Certification feature, you can create and issue certificates for course completions, ensuring that employees are acknowledged for their efforts and skills. ",
    image: <StarIcon />
  },
  {
    title: "Training Calendar",
    description: "Keep your training schedule organized with the Training Calendar feature. Easily plan, schedule, and manage training sessions across your organization. Employees can view upcoming training events, register for sessions, and receive reminders, ensuring they never miss important learning opportunities.",
    image: <EventIcon />
  },
  {
    title: "Integrations",
    description: "Enhance your learning management system with powerful integrations. Connect with other ERP modules, HR systems, and third-party tools to streamline workflows and data sharing. Our Integrations feature ensures that your training processes are cohesive and connected, providing a comprehensive learning experience.",
    image: <IntegrationInstructionsIcon />
  },

]
const content8 = [
  {
    title: "Align Goals with Objectives and Key Results",
    description: "The OKRs (Objectives and Key Results) feature helps you set, track, and achieve organizational and team goals. This tool allows employees to define clear objectives along with measurable key results, aligning individual efforts with company-wide strategic goals. ",
    image: <PieChartIcon />
  },
  {
    title: "Conduct Comprehensive Performance Reviews",
    description: "The Reviews feature streamlines the process of conducting regular performance evaluations. It enables managers to provide structured feedback, assess employee performance against set goals, and identify areas for improvement.",
    image: <RateReviewIcon />
  },
  {
    title: "Gather Comprehensive Feedback from Multiple Perspectives",
    description: "This feature offers a well-rounded perspective on skills, behaviors, and competencies, highlighting strengths and areas for development. The anonymity and multiple viewpoints encourage honest feedback, fostering a culture of continuous improvement and personal growth.",
    image: <PeopleIcon />
  },
  {
    title: "Visualize Employee Performance and Potential",
    description: "The M-box Matrix feature allows you to visually map employee performance against potential, helping identify high performers, emerging leaders, and areas needing development. This tool uses a grid to classify employees based on various performance and potential criteria, aiding in talent management and decision-making. ",
    image: <BarChartIcon />
  },
  {
    title: "Succession Planning",
    description: "Succession Planning helps you identify and develop future leaders within the organization. This feature enables you to map out key positions and the employees who are potential successors, ensuring a smooth transition and continuity in leadership roles.",
    image: <TrendingUpIcon />
  },
  {
    title: "Career Development Plan",
    description: "The Career Development Plan feature supports employees in achieving their long-term career goals by mapping out personalized development paths. This tool helps identify skills gaps, set career objectives, and outline actionable steps for professional growth.",
    image: <CalendarTodayIcon />
  },

]

const MainTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '3rem',
  marginBottom: '1rem',
  fontFamily: '"Poppins", sans-serif !important',
  color: '#474d66'
});

const SubTitle = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '2rem',
  fontFamily: '"Poppins", sans-serif !important',
  color: '#8f95b2',


});

const HeroContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#007bff',
  color: '#fff',
  borderRadius: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridRowGap: '30px',
  width: '100%',
  maxWidth: '1000px',
  margin: '0 auto',
  maxHeight: '300px'
}));

const HeroLogo = styled(Box)({
  display: 'inline-block',
  backgroundColor: '#fff',
  borderRadius: '50%',
  padding: '25px',
  marginBottom: '20px',
  marginTop: '20px',

});
const HeroButton = styled(Button)({
  height: '60px',
  width: '160px',
  borderRadius: '100px !important',
  /* padding: 8px 13px; */
  transition: '0.2s',
  textTransform: 'none!important',
  boxShadow: 'none'

});

const ExploreButton = styled(Button)({
  lineHeight: 1.3, 
  fontWeight: 500, 
  backgroundColor: '#fff', 
  border: '1px solid #d6d6d8', 
  textTransform: 'none !important', 
  padding: '1rem 2rem', 
  width: '220px', 
  whiteSpace:'nowrap',
  borderRadius: '4rem',
   marginTop: '30px !important', 
   fontFamily: '"Poppins", sans-serif !important', 
   fontSize: '16px',
  '&:hover': {
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  },
  '@media (max-width: 768px)': {
    marginLeft: '-10px', 
  },

})

const MainTypo = styled(Typography)({
  color: '#fff',
  fontWeight: 500,
  lineHeight: 1.3,
  fontFamily: 'poppins, sans-serif !important '
})

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});


const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.up('md')]: {
      width: '550px !important', 
      height: '450px !important', 
      objectFit: 'cover', 
      borderRadius: '20px', 
      transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important', 
      objectFit: 'cover', 
      borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
  },
  image1: {
    [theme.breakpoints.up('md')]: {
      width: '670px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
  },
  image2: {
    [theme.breakpoints.up('md')]: {
      width: '685px !important', height: '390px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
  },
  image3: {
    [theme.breakpoints.up('md')]: {
      width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
      },
    },
  },
  imageContainer: {
    borderRadius: '12px',
    aspectRatio: '1.84',
    textAlign: 'left !important', // Center text for consistency
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      paddingTop: '0 !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60% !important', // Adjust maxWidth to fit design
      paddingTop: '0 !important',
      paddingLeft: '14px !important',
      textAlign: 'center !important',
    }
  },
  imageContainer1: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '12px',
      maxWidth: '100% !important',
      aspectRatio: '1.84',
      paddingTop: '0 !important',
      paddingLeft: '4px !important',
      textAlign: 'left !important',
      marginTop: '10px'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      maxWidth: '55% !important',
      aspectRatio: '1.84',
      paddingTop: '0 !important',
      paddingLeft: '14px !important',
      textAlign: 'center !important',

    }

  },
  imageContainer2: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '12px',
      maxWidth: '100% !important',
      aspectRatio: '1.84',
      paddingTop: '0 !important',
      paddingLeft: '4px !important',
      textAlign: 'left !important',
      marginTop: '10px'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      maxWidth: '60% !important',
      aspectRatio: '1.84',
      paddingTop: '2% !important',
      paddingLeft: '14px !important',
      textAlign: 'center !important',
      alignItems: 'center'


    }

  },
  imageContainer3: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '12px',
      maxWidth: '100% !important',
      aspectRatio: '1.84',
      paddingTop: '0 !important',
      paddingLeft: '4px !important',
      textAlign: 'left !important',
      marginTop: '10px'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      maxWidth: '60% !important',
      aspectRatio: '1.84',
      paddingTop: '0 !important',
      paddingLeft: '14px !important',
      textAlign: 'center !important',

    }

  },
  gridRoot: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90% !important'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
    }
  },
  gridRoot1: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90% !important'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
    }
  },
  gridRoot2: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90% !important'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
    }
  },
  gridRoot3: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90% !important'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
    }
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      color: '#6D5DF6',
      flexDirection: 'column !important', // Ensure vertical alignment
      border: '1px solid #FAF9FC', // Border color as background color
      borderRadius: '20px',
      height: '370px !important', // Set height
      textAlign: 'center',
      justifyContent: 'center', // Distribute space around content
      padding: '16px', // Adjusted padding
      backgroundColor: '#FFFFFF', // Set background color
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      maxWidth: '100%',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      color: '#0080e5',
      flexDirection: 'column !important', // Ensure vertical alignment
      border: '1px solid #FAF9FC', // Border color as background color
      borderRadius: '20px',
      height: '300px !important', // Set height
      textAlign: 'center',
      justifyContent: 'flex-start', 
      padding: '16px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 18px -4px rgba(177,177,177,.7)',
      width: '100% !important'

    }
  },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80% !important',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25.333% !important',
    }
  },

}));



const HRMS = () => {
  const classes = useStyles();
  const { ref: containerRef, inView: containerInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef1, inView: containerInView1 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef2, inView: containerInView2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef3, inView: containerInView3 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef4, inView: containerInView4 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef5, inView: containerInView5 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef6, inView: containerInView6 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef7, inView: containerInView7 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRef8, inView: containerInView8 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRefHero, inView: containerHero } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });


  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ margin: '2% auto', maxWidth: '100% !important', padding: '0 !important' }}>

      <Grid size='large' container spacing={4} alignItems="center" sx={{ maxWidth: '90%', margin: '0 auto 40px', display: { xs: 'none', md: 'flex' }, alignItems: 'flex-start', minHeight: '80vh' }}>

<Grid item xs={12} md={6} sx={{ maxWidth: '45% !important', paddingTop: '55px !important', paddingLeft: '0 !important', minHeight: '100%' }}>

    <MainTitle sx={{ textAlign: 'left', wordBreak: 'break-word', fontSize: '6vh' }}>
    Optimize your everyday HR functions with  <Typography component="span" sx={{ fontSize: { xs: '24px', md: '6vh' }, color: '#0080e5', fontWeight: 'bold' }}>HRMS </Typography>system

    </MainTitle>
    <SubTitle>
    The Only HRMS Management Software that packs in Everything you need for Managing your Entire Employee Lifecycle </SubTitle>

    <Box
        textAlign="center"
        mb={4}
        sx={{
            margin: { xs: '10px 0', md: '32px 0' },
            width: { xs: '100%', sm: '80%', md: '70%' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-around',
        }}
        >
        <Button
            variant="contained"
            color="primary"
            sx={{
            margin: { xs: '10px auto', sm: '0 8px' },
            lineHeight: '24px',
            fontWeight: 'bold',
            backgroundColor: '#0080ef',
            border: '1px solid #d6d6d8',
            textTransform: 'none',
            padding: '1rem 1rem',
            width: { xs: '100%', sm: '220px' },
            borderRadius: '4rem',
            fontSize: '12px',
            '&:hover': {
                backgroundColor: '#0070d4',
                 transform:'scale(0.9)'
            },
            }}
        >
            Explore Demo Login
        </Button>
        <Button
            variant="outlined"
            color="primary"
            href="/request-demo"
            sx={{
            margin: { xs: '10px auto', sm: '0 8px' },
            lineHeight: 1.5,
            border: '2px solid #0080e5',
            textTransform: 'none',
            padding: '1rem 1rem',
            width: { xs: '100%', sm: '220px' },
            borderRadius: '4rem',
            fontSize: '12px',
            '&:hover': { background: '#edeff5', transform:'scale(0.9)' },
            }}
        >
            Book a Demo
        </Button>
        </Box>

    </Grid>
    <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{ 
            borderRadius: '12px', 
            maxWidth: '100%', 
            aspectRatio: '1.5', 
            paddingTop: { xs: '10px', md: '10px' }, 
            paddingLeft: { xs: '0', md: '14px' }, 
            textAlign: 'center', 
            height: 'auto' 
          }}
        >
          <Box
            component="img"
            src={hrms} 
            alt="loading"
            sx={{

              width: '100%',
              borderRadius: '12px',
            }}
          />
        </Grid>
    </Grid>
    <Grid
    container
    spacing={4}
    alignItems="center"
    sx={{
    minHeight: '60vh',
    maxWidth: { xs: '100%', sm: '90%', md: '400px' },
    margin: '0 auto 40px',
    display: { xs: 'flex', md: 'none' },
    textAlign: 'center',
    }}
    >
    <Grid 
    item xs={12} 
    md={6} sx={{ textAlign: 'center',
        marginLeft:{
            xs : '-10px'
        }
    }}>
    <MainTitle
    sx={{
        fontSize: { xs: '24px', sm: '28px', md: '32px' },
        textAlign: 'center',
        wordBreak: 'break-word',
    }}
    >
    Optimize your everyday HR functions with{' '}
    <Typography
        component="span"
        sx={{
        fontSize: { xs: '28px', sm: '32px', md: '6vh' },
        color: '#0080e5',
        fontWeight: 'bold',
        }}
    >
        HRMS{' '}
    </Typography>
    system
    </MainTitle>
    <SubTitle>
    The Only HRMS Management Software that packs in Everything you need for Managing your Entire Employee Lifecycle</SubTitle>
    <Box
    textAlign="center"
    mb={4}
    sx={{
        margin: { xs: '10px 0', md: '32px 0' },
        width: { xs: '100%', sm: '80%', md: '70%' },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-around',
    }}
    >
    <Button
        variant="contained"
        color="primary"
        sx={{
        margin: { xs: '10px auto', sm: '0 8px' },
        lineHeight: '24px',
        fontWeight: 'bold',
        backgroundColor: '#0080ef',
        border: '1px solid #d6d6d8',
        textTransform: 'none',
        padding: '1rem 1rem',
        width: { xs: '100%', sm: '220px' },
        borderRadius: '4rem',
        fontSize: '12px',
        '&:hover': {
            backgroundColor: '#0070d4',
        },
        }}
    >
        Explore Demo Login
    </Button>
    <Button
        variant="outlined"
        color="primary"
        href="/request-demo"
        sx={{
        margin: { xs: '10px auto', sm: '0 8px' },
        lineHeight: 1.5,
        border: '2px solid #0080e5',
        textTransform: 'none',
        padding: '1rem 1rem',
        width: { xs: '100%', sm: '220px' },
        borderRadius: '4rem',
        fontSize: '12px',
        '&:hover': { background: '#edeff5' },
        }}
    >
        Book a Demo
    </Button>
    </Box>
    </Grid>
    <Grid item xs={12} md={6} 
    sx={{ maxWidth: '100%', 
        width: '100%', 
        paddingLeft: 0, 
        overflow: 'hidden' }}>
    <Box
    sx={{
        width: '100%',
        height: { xs: 'auto', sm: '370px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
    <img
        src= {hrms}
        style={{
        width: '100%',
        maxWidth: { xs: '100%', sm: '370px' },
        height: 'auto',
        }}
        alt="CRM GIF"
    />
    </Box>
    </Grid>
    </Grid>

      
        <Grid
        container
        spacing={4}
        alignItems="flex-start"
        sx={{
          minHeight: {
            xs : '120vh',           
            md : '110vh',
          },
          maxWidth: '100% !important',
          margin: '60px 0 auto',
          justifyContent: { xs: 'center', md: 'space-around' },
          background: '#0080e5',
          color: '#F1EEFF',
          padding: '30px 0 !important',
        }}
        ref={containerRef}
      >
        <Grid
          item
          sx={{
            maxHeight: '100%',
            paddingTop: { xs: '0 !important', md: '10% !important' },
            textAlign: 'left',
          }}
          className={classes.gridRoot}
        >
          <MainTypo 
          sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
          Recruitment Management
          </MainTypo>
          <Typography 
          sx={{ 
            marginTop:{xs : '20px',md:'20px'},
            fontFamily: '"Poppins", sans-serif' }}>
            Streamline your hiring process with our Applicant Tracking System (ATS). Integrate seamlessly with platforms like LinkedIn, Naukri, and Glassdoor to source top talent efficiently. Optimize candidate experience and improve recruitment outcomes with advanced analytics and automation.
          </Typography>
          <ExploreButton 
            className={containerInView ? 'expand-animation' : ''}            
          >
            Explore Demo Login
          </ExploreButton>
        </Grid>
        <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={hs1}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>

      </Grid>

      
        <Grid
            container
            spacing={2}
            className="container-grid"
            sx={{
              marginTop: { xs: '-40px', md: '-120px' },
              justifyContent: 'center', 
              textAlign: 'center',
              alignItems: 'stretch',
            }}
          >
          {content.map((item, index) => (
            <Grid 
              item 
              xs={12} 
              md={4}   
              key={index} 
              sx={{
                display: 'flex',
                justifyContent: 'center',  
                maxWidth: { md: '25.333% !important' },
              }}
            >
            <Box 
              sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}
            >
            <Icon 
              sx={{ 
                textAlign: 'center', 
                margin: { xs: '0 auto 16px', md: '0 auto' }, 
                color: '#0080e5' 
              }}
            >
              {item.image}
            </Icon>
            <Box sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  background: 'none', 
                  color: '#0080e5', 
                  fontFamily: '"Poppins", sans-serif !important' 
                }}
              >
                {item.title}
              </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    background: 'none', 
                    color: 'black', 
                    fontFamily: '"Poppins", sans-serif !important' 
                  }}
                >
                  {item.description}
                  </Typography>
                </Box>
              </Box>
                </Grid>
              ))}
        </Grid>





        {/* First Section Ends here */}



        <Grid container spacing={4} 
        alignItems="flex-start" 
        sx={{ 
          minHeight: {xs : '130vh',md:'100vh'}, 
          maxWidth: '100% !important', 
          margin: '60px 0  auto', 
          display: { xs: 'flex', md: 'flex' }, 
          justifyContent: { xs: 'center', md: 'space-around' }, 
          maxHeight: '100vh', 
          background: '#f9a400', 
          color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef1}
        >
        <Grid
          sx={{
            marginTop: '40px',
           
          }}
        >
          <Box
            component="img"
            src={hs2}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
            <MainTypo 
              sx={{ 
                fontSize: { xs: '5vh', md: '6vh' } }}
              >
              Time and Attendance
            </MainTypo>
            <Typography 
              sx={{marginTop:{xs:'20px',md:'0px'},fontFamily: '"Poppins", sans-serif' }}
            >
              Track employee time and attendance effortlessly. Our system provides accurate timekeeping, ensuring compliance and reducing payroll errors. Gain real-time insights into workforce attendance patterns and manage shifts with ease.
            </Typography>
            <ExploreButton 
              className={containerInView1 ? 'expand-animation' : ''} 
              sx={{ 
                margin: { xs: '10px auto', md: '0' }, 
                color: '#f9a400' ,
                marginBottom:'20px'
              }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>

        </Grid>


        <Grid 
          container 
          spacing={1} 
          className="container-grid" 
          sx={{
            marginTop: { xs: '-40px', md: '-120px' },
            justifyContent: 'center', 
            textAlign: 'center',
            alignItems: 'stretch',
          }}
        >
          {content1.map((content, index) => (
            <Grid item 
              xs = {12}
              md={4} 
              key={index} 
              sx = {{
                display : "flex",
                justifyContent:'center',
                maxWidth : {md :'25.333% !important'}
              }}
              className={classes.gridItem} >
              <Box              
                sx={{
                  backgroundColor: '#fff',  
                  padding: '16px',         
                  borderRadius: '8px',     
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                  display: 'flex',
                  flexDirection: 'column', 
                  alignItems: 'center',   
                  width: { xs: '80%', md: '100%' },  
                }}
              >
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#f9a400', }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#f9a400', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} 
          alignItems="flex-start" 
          sx={{ 
            minHeight:{xs : '125vh',md:'100vh'}, 
            maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#008c44', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef2}
        >
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot2}>
            <MainTypo 
              sx={{ fontSize: { xs: '5vh', md: '6vh' } }}
            >
              Leave Management
            </MainTypo>
            <Typography 
              sx={{ fontFamily: '"Poppins", sans-serif', 
              fontSize: '18px', color: '#fff',
              marginTop:{xs : '20px',md:'0px'},

              }}>
              Simplify leave management with our intuitive system. Employees can apply for leave online, and managers can approve or reject requests with a single click. Keep track of leave balances, generate reports, and ensure smooth workflow without disruptions.

            </Typography>
            <ExploreButton 
              className={containerInView2 ? 'expand-animation' : ''} 
              sx={{ 
                margin: { xs: '10px auto', md: '0' }, color: '#008c44',
                marginLeft:{
                  xs : '-2px',
                  md : '0px'
                }
              }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>
          <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={hs3}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
        </Grid>
        <Grid 
          container 
          spacing={1} 
          className="container-grid" 
          sx={{ 
            
            marginTop: { xs: '-40px', md: '-120px' }, 
            justifyContent: 'center', 
            textAlign:'center',
            alignItems:'stretch'
          }}
        >
          {content2.map((content, index) => (
            <Grid 
            item 
            xs={12} 
            md={4}   
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: 'center',  
              maxWidth: { md: '25.333% !important' },
            }}
            >
              <Box 
                sx={{
                  backgroundColor: '#fff',  
                  padding: '16px',         
                  borderRadius: '8px',     
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                  display: 'flex',
                  flexDirection: 'column', 
                  alignItems: 'center',   
                  width: { xs: '80%', md: '100%' },  
                }}                           
              >
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#008c44' }} > {content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#008c44', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid 
          container 
          spacing={4} 
          alignItems="flex-start" 
          sx={{ minHeight:{xs : '135vh',md : '110vh'}, 
          maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f1352c', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef3}
        >
          <Grid>
          <img
            src={hs4}
            alt="Recruitment"
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '450px', 
              display: 'block',
              margin: '0 auto',
              borderRadius:'20px'
            }}
          />
          </Grid>
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
            <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
              Core HR
            </MainTypo>
            <Typography 
              sx={{ fontFamily: '"Poppins", sans-serif',
                marginTop:{
                  xs :'20px',
                  md : '0px'
                }
               }}>
              Manage your employee data effectively with our comprehensive Core HR module. Maintain detailed employee records, streamline onboarding processes, and ensure data accuracy. Enhance your HR operations with centralized employee information.

            </Typography>
            <ExploreButton className={containerInView3 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f1352c' }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>

        </Grid>
        <Grid container
          spacing={2}
          className="container-grid"
          sx={{
            marginTop: { xs: '-40px', md: '-120px' },
            justifyContent: 'center', 
            textAlign: 'center',
            alignItems: 'stretch',
          }}
        >
          {content3.map((content, index) => (
            <Grid  item 
            xs={12} 
            md={4}   
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: 'center',  
              maxWidth: { md: '25.333% !important' },
            }}>
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}
            >
              <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#f1352c' }} >{content.image}</Icon>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ background: 'none', color: '#f1352c', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
              </Box>
            </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} 
          alignItems="flex-start" 
          sx={{ minHeight:{xs:'120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef4}
        >
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
            <MainTypo 
              sx={{ fontSize: { xs: '5vh', md: '6vh' },
               }}>
              Payroll
            </MainTypo>
            <Typography sx={{ fontFamily: '"Poppins", sans-serif',marginTop:{
              xs :'20px',
              md : '0px'
            } }}>
              Automate payroll processing and ensure accurate and timely salary disbursements. Our system handles complex calculations, tax deductions, and statutory compliance effortlessly. Generate payslips, manage bonuses, and streamline payroll operations.
            </Typography>
            <ExploreButton className={containerInView4 ? 'expand-animation' : ''} 
            sx={{ margin: { xs: '10px auto', md: '0' }, 
            color: '#0080e5',
            marginLeft:{
              xs:'-5px',
              md : '0px'
            }
            }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>
          <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={hs5}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '300px', sm: '300px', md: '450px', lg: '450px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
        </Grid>


          <Grid container spacing={1} 
          className="container-grid" sx={{
              marginTop: { xs: '-40px', md: '-120px' },
              justifyContent: 'center', 
              textAlign: 'center',
              alignItems: 'stretch',
            }}
          >
          {content4.map((content, index) => (
            <Grid 
            item 
            xs={12} 
            md={4}   
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: 'center',  
              maxWidth: { md: '25.333% !important' },
            }}
          >
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}
              >
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#0080e5' }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} 
          alignItems="flex-start" 
          sx={{ minHeight:{xs:'135vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f9a400', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef5}
        >
        <Grid
          sx={{
            marginTop: '40px',
          }}
        >
          <Box
            component="img"
            src={hs6}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '300px', sm: '300px', md: '450px', lg: '450px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
            <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' },

          }}>
              Expense Management
            </MainTypo>
            <Typography sx={{ fontFamily: '"Poppins", sans-serif',
              marginTop:{
                xs : '20px',
                md : '0px'
              }
            }}>
              Take control of employee expenses with our efficient Expense Management module. Simplify the process of submitting, approving, and reimbursing expenses. Monitor spending, enforce policies, and gain insights into expense trends.

            </Typography>
            <ExploreButton 
              className={containerInView5 ? 'expand-animation' : ''} 
              sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f9a400',
              marginLeft:{
                xs : '-1px',
                md : '0px'
              }
              
              }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>

        </Grid>
        <Grid container spacing={1} className="container-grid" sx={{
              marginTop: { xs: '-40px', md: '-120px' },
              justifyContent: 'center', 
              textAlign: 'center',
              alignItems: 'stretch',
            }}>
          {content5.map((content, index) => (
           <Grid 
           item 
           xs={12} 
           md={4}   
           key={index} 
           sx={{
             display: 'flex',
             justifyContent: 'center',  
             maxWidth: { md: '25.333% !important' },
           }}
         >
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}>
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#f9a400' }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#f9a400', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} alignItems="flex-start" sx={{ 
          minHeight:{xs :'135vh',md:'105vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#008c44', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef6}
        >
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot2}>
            <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
              Employee Engagement

            </MainTypo>
            <Typography sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '18px', color: '#fff',
              marginTop:{
                xs : '20px',
                md:'0px'
              }
             }}>
              Boost employee morale and engagement with our interactive platform. Conduct surveys, gather feedback, and measure employee satisfaction. Foster a positive work environment and enhance overall productivity with actionable insights.

            </Typography>
            <ExploreButton className={containerInView6 ? 'expand-animation' : ''} 
              sx={{ margin: { xs: '10px auto', md: '0' }, color: '#008c44',
                marginLeft:{xs : '-5px',md:'0px'}
              }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>
          <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={hr7}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
        </Grid>
        <Grid
            container
            spacing={2}
            className="container-grid"
            sx={{
              marginTop: { xs: '-40px', md: '-120px' },
              justifyContent: 'center', 
              textAlign: 'center',
              alignItems: 'stretch',
            }}
          >
          {content6.map((content, index) => (
            <Grid item 
              xs={12} 
              md={4}   
              key={index} 
              sx={{
                display: 'flex',
                justifyContent: 'center',  
                maxWidth: { md: '25.333% !important' },
              }}
            >
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}>
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#008c44' }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#008c44', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} alignItems="flex-start" 
          sx={{ minHeight:{ xs : '130vh',md:'105vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f1352c', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef7}
        >
        <Grid
          sx={{
            marginTop: '40px',
            paddingLeft:{xs:'0px',md:'20px'}
          }}
        >
          <Box
            component="img"
            src={hr8}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
            <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
              Learning Management
            </MainTypo>
            <Typography sx={{ fontFamily: '"Poppins", sans-serif',marginTop:{xs:'20px',md:"0px"} }}>
              Empower your workforce with continuous learning opportunities. Our Learning Management System (LMS) offers a wide range of training programs, courses, and development tools. Track progress, assess performance, and support employee growth.

            </Typography>
            <ExploreButton className={containerInView7 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f1352c' }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>

        </Grid>
        <Grid container spacing={1} className="container-grid"  
        
          sx={{
            marginTop: { xs: '-40px', md: '-120px' },
            justifyContent: 'center', 
            textAlign: 'center',
            alignItems: 'stretch',
          }}
        >
          {content7.map((content, index) => (
            <Grid               item 
            xs={12} 
            md={4}   
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: 'center',  
              maxWidth: { md: '25.333% !important' },
            }} >
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}
            >
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#f1352c' }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#f1352c', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >



        <Grid container spacing={4} alignItems="flex-start" sx={{ 
          minHeight:{xs:'135vh',md:'105vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}
          ref={containerRef8}
        >
          <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
            <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
              Performance Management
            </MainTypo>
            <Typography sx={{ fontFamily: '"Poppins", sans-serif',marginTop:{xs : '20px',md:'0px'} }}>
              Optimize employee performance with our comprehensive Performance Management module. Set goals, conduct performance reviews, and provide feedback. Use analytics to identify strengths and areas for improvement, ensuring consistent performance enhancement.
            </Typography>
            <ExploreButton className={containerInView8 ? 'expand-animation' : ''} 
              sx={{ margin: { xs: '10px auto', md: '0' }, color: '#0080e5' }}>
              Explore Demo Login
            </ExploreButton>
          </Grid>
          <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={hr9}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
        </Grid>
        <Grid 
         container
         spacing={1}
         className="container-grid"
         sx={{
           marginTop: { xs: '-40px', md: '-120px' },
           justifyContent: 'center', 
           textAlign: 'center',
           alignItems: 'stretch',
         }}
        >
          {content8.map((content, index) => (
            <Grid 
            item 
            xs={12} 
            md={4}   
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: 'center',  
              maxWidth: { md: '25.333% !important' },
            }}
          >
              <Box sx={{
                backgroundColor: '#fff',  
                padding: '16px',         
                borderRadius: '8px',     
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',   
                width: { xs: '80%', md: '100%' },  
              }}>
                <Icon sx={{ textAlign: 'center', margin: '0 auto', color: '#0080e5' }} >{content.image}</Icon>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                  <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid >
      </Container >


          {/* Just Fusion ERP */}
          <Container
          sx={{
            marginTop: '0 !important',
            minHeight: '50vh', 
            height: 'auto',
            paddingTop: { xs: '0px', md: '10px', lg: '10px' },
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: { xs: '0 auto', md: '0 auto !important' },
            width:'1240px !important',
            maxWidth:'100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={containerRefHero}
        >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '55vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>

      {/* Just FUSIONERP ENDS */}

      <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-60px',  
                    lg: '-80px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
            </Container>
    </ThemeProvider>


  );
};

export default HRMS;



