import React, { useState,forwardRef } from 'react';
import { Box, Paper, TextField, Button, Typography, Grid } from "@mui/material";
import Footer from "./Footerhcm";
import {Snackbar} from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Fusionerpcontactus = () => {


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    mobileNo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      
      if (result.message) {
        if (typeof result.message === 'string') {
            setSnackbarMessage(result.message);
            setSnackbarSeverity('success');
            setSnackbarTitle('Success');
            setSnackbarOpen(true);
        } else if (result.message && result.message.message && result.message.message.error) {
          let errorMessage = result.message.message.error;
          setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
          setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
          setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
          setSnackbarOpen(true);
        }
      }
      
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarTitle('Error');
      setSnackbarOpen(true);
    }
  };
  

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '70vh',
          width: '100%',
          bgcolor: '#1976d2',
          padding: { xs: '20px', md: '0 70px' },
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            flex: 1,
            color: 'white',
            padding: { xs: '0 20px', md: '0px 60px' },
            textAlign: 'left',
            marginBottom: { xs: '20px', md: '0' }
          }}
        >
          <Typography sx={{ fontWeight: 800, fontSize: { xs: '24px', md: '30px' } }}>
            Unlock Business Growth: <br />
            Consult with Our Expert 
            <br />
            Team
          </Typography>
          <Typography sx={{ fontSize: '14px', marginTop: '20px' }}>
            Our experts are available to assist you in determining <br />
            the best path forward to business growth.
          </Typography>
        </Box>

        
        <Paper
          elevation={8}
          sx={{
            width: '100%',
            maxWidth: {xs:'100%',md:'500px',lg:'600px'},
            padding: { xs: '20px', md: '40px' },
            bgcolor: 'white',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                height: '100%',
              }}
            >
              <TextField
                label="Full Name"
                name="name"
                variant="outlined"
                fullWidth
                size="small"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                size="small"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                label="Company Name"
                name="companyName"
                variant="outlined"
                fullWidth
                size="small"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
              <TextField
                label="Mobile No."
                name="mobileNo"
                type="tel"
                variant="outlined"
                fullWidth
                size="small"
                value={formData.mobileNo}
                onChange={handleChange}
                required
              />
              <TextField
                label="Question/comments"
                name="agenda"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                size="small"
                value={formData.agenda}
                onChange={handleChange}
                required
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          padding: { xs: '20px', md: '50px 70px' },
          boxSizing: 'border-box',
          bgcolor: 'white',
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: { xs: '0 20px', md: '0 120px' },
            textAlign: 'left'
          }}
        >


          <Typography variant="h6" 
            sx={{ fontWeight: 'bold',color:'#689f38' }}>For Sales</Typography>

          
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'flex-start',
            ml : 0.5
            }}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                <a href="tel:+91 99401 81721" style={{ textDecoration: 'none',color:'#007ee5' }}>+91 98864 84831</a>
              </Typography>
            </Box>


            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent:'flex-start',
                ml : 0.5 }}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <a href="mailto:info@fusionerp.in" style={{ textDecoration: 'none',color:'#007ee5' }}>info@fusionerp.in</a>
                </Typography>
              </Box>


          <Typography mt = {5}  ml ={1}>Do you wish to be a part of our tribe?</Typography>
          <Typography>
            <Button sx={{ fontWeight: 800,color:'#007ee5'}}>Email Sales</Button>
            <Button sx={{ fontWeight: 800,color:'#007ee5' }}>WhatsApp</Button>
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            padding: { xs: '0 20px', md: '0 50px 0 150px' },
            textAlign:'flex-start'
          }}
        >
          <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 'bold',
            color: '#d50000',
            marginLeft:'-5px'
            
          }}
          
          >General</Typography>
          <Typography sx = {{marginLeft:'10px'}}>Can't find what you are looking for?</Typography>
          <Typography>
            <Button sx={{ fontWeight: 800,marginLeft:'4px' }}>Say Hello</Button>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          bgcolor: 'white',
        }}
      >
        <Box sx={{ fontSize: { xs: '30px', md: '50px' }, textAlign: 'center', padding: '20px', color: 'dimgrey', fontWeight: 700 }}>
          Fusionerp offices around the globe.
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ padding: { xs: '20px', md: '0 80px' }, marginTop: { xs: '-50px', md: '-50px' }, bgcolor: 'white', justifyContent: 'center' }}>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '21px', color: 'dimgrey',marginRight:'20px' }}>Bengaluru</Typography>
            <Typography sx={{ color: '#1976d2', fontSize: '16px', fontWeight: 400, marginRight: '30px' }}>India</Typography>
            <Box sx={{ marginTop: '16px' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: "bold" }}>Registered Office</Typography>
              <Typography sx={{ fontSize: '15px' }}>IndiQube Delta, 06, 14th Main Rd, opp. Agara,</Typography>
              <Typography sx={{ fontSize: '15px' }}>Lake, HSR Layout 5th Sector,</Typography>
              <Typography sx={{ fontSize: '15px' }}>Bengaluru - 560068</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarTitle && <strong>{snackbarTitle}</strong>} {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default Fusionerpcontactus;
