import FusionerpcallScript from '../components/FusionerpcallScript';
import Fivestarrating from '../components/Fivestarrating';
import OurProducts from '../components/OurProducts';
import Questions from "../components/Questions";
import Contactonehas from '../components/Contactonehas';

const Fusionerpcall = () => {
  return (
    <>			 
      <FusionerpcallScript />
      <Fivestarrating />
      <OurProducts />
      <Contactonehas />
      <Questions /> 
    </>
  )
}

export default Fusionerpcall