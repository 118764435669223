import React from "react";
import { Typography, Container, Button, Box, Grid, Icon } from "@mui/material";
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Footer from "./Footerhcm";
import erp from '../images/erp-main-img.png';
import { useInView } from 'react-intersection-observer';
import hrr from '../images/erp-hr-main-2.png';

const content = [
    {
        title: "HR Processing",
        description: "Setting up your HR processing is as easy as OneHash’s onboarding process. The HR settings consist of major settings related to employee lifecycle, leave management, and more.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/611143f9522ac102a2adcb6f_Data%20Enrichment.svg",
    },
    {
        title: "Employee Lifecycle",
        description: "Capture employee demographic, joining and leave details, employee grade and designation, health insurance, and promotion and transfer details. All of this and more in one place. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d205d7ce38fde11b7afba_Employee%20Lifecycle.svg",
    },
    {
        title: "Recruitment, Training, and Performance Records",
        description: "Keep track of job applicants, offers, and staffing plans. Create employee appraisals and appointment letters through a preset template, and schedule training programs to receive updates.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114705bb0a89001f581525_Interactive%20Dashboards.svg",
    },
    {
        title: "Shift, Leave, and Attendance Management",
        description: "Manage your team’s shifts, assign tasks, set their shift timings, and keep tabs on requests and approvals. Create leave type, period, policy, application, and attendance types and requests.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6111471a314973b7abf26421_Strategic%20Risk%20Analysis.svg",
    },
    {
        title: "Payroll Management",
        description: "Create salary components, structure, and slips. Keep track of payroll period, income tax slab, employee tax exemptions, and compensations like additional salary, retention bonus, incentives, and more. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6111472940b4ec2416e826f2_Insightful%20Leaderboards.svg",
    },
    {
        title: "Master Reports",
        description: "Review how efficiently your employees work, keep tabs on their expense claims and advances, monthly attendance sheets, recruitment, and employee analytics, leave balance summaries, salary payments, tax deductions, and bank remittances.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d205be5bd5a794ddd6202_Master%20Reports.svg",
    },

]
const content1 = [
    {
        title: "Comprehensive Accounting",
        description: "Create a chart of accounts, use fiscal year details for setting sales goals, and maintain the financial book payment tems. Make journal entries, create templates and generate general, customer, and supplier ledger summaries. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d209fd053e45752506c20_Comprehensive%20Accounting.svg",
    },
    {
        title: "Accounts Receivable and Payable ",
        description: "Create sales and purchase invoices, customer and supplier details, payment entries and requests, accounts receivable summaries, sales order analysis, purchase order summaries and analysis, and items to be billed. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d20dd7ce38fcdb5b7b64d_Accounts%20Receivable%20and%20Payable.svg",
    },
    {
        title: "Budgeting, Taxes, and Profitability",
        description: "Create monthly disruptions and budget reports against chart of cost center, allowing you to keep tabs on costs and income. Create sales, charges, and item templates and understand their implications. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b85f41b46242deae789_Customer%20service%20and%20support.svg",
    },
    {
        title: "Financial Statements",
        description: "Review the trial balance and profit and loss statement. Maintain a balance sheet,cash flow and generate a consolidated financial statement. Evaluate the currency and exchange rate efficiently.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114bdcd48cb811d5a4e12e_track%20Campaign.svg",
    },
    {
        title: "Banking, Share and Subscription Management",
        description: "Manage your bank accounts, clearances, and reconciliation statements. Make payment entries and reconciliations, and manage your subscription plan. With OneHash ERP, you can also maintain shareholders and share transfer details. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b9ff41b4602c7eae825_Customer%20Segmentation.svg",
    },
    {
        title: "Master Reports",
        description: "Review a party's trial balance and the payment period based on invoice dates and sales partners' commission. Understand customer credit balance, sales payment summary with address and contacts analysis",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114bb4ed9cf0750af4f096_CustomerChurn.svg",
    },

]
const content2 = [
    {
        title: "Purchase Management",
        description: "Consolidate your buying procedures by intuitively centralising data management. Set the re-order level and automatically generate the purchase order. Add additional discounts based on the total and handle other accounting matters. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125930243f86583f386771_automate%20approval.svg",
    },
    {
        title: "Warehouse Management",
        description: "Maintain a warehouse in the tree structure so that the location and sub-location of an item can be tracked. Link it to a specific accounting ledger to view the real-time stock value of items.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592fc2f0700adcbfd42e_Automatized%20Emails%20and%20SMS%20Campaigns.svg",
    },
    {
        title: "Stock Management",
        description: "Gain quick access to stock entry, purchase receipts, delivery notes, stock ledge, and stock balance. Address issues related to theft and pilferage to ensure that the movement of goods happens against a document reference.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592dce9869194ad0d715_automate%20your%20finance.svg",
    },
    {
        title: "Inventory Management",
        description: "Manage all business aspects from a single platform, from inventory, planning, and logistics to finance and operations. Inventory management gives actionable insights about workflows and supply chains, among many others.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592dda6c0e54edb8c8a1_Alerts%20and%20Notifications.svg",
    },
    {
        title: "Serial No. and Batch",
        description: "Maintain a serial number for each quantity of an Item. Keep track of serial no.'s location, warranty, and end-of-life information. Group multiple units of an item and assign them a unique batch number.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592ee9d2e71bcbaeb6e7_Inbuilt%20omni-channel%20Integrations.svg",
    },
    {
        title: "Master Reports",
        description: "Receive key reports for purchases, sales orders, and stock analytics. Get access to note trends, purchase receipt trends, batch-wise balance history, requested items to be transferred, item shortage reports, and more. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d21ca9150bf6bf990102a_Master%20Reports%20(1).svg",
    },

]
const content3 = [
    {
        title: "Task Management",
        description: "Create a task to define a particular actionable assigned to an individual project. Choose to define the task's priority, viz., low, medium, high or urgent. Calculate the task weight and segregate them in different colors. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1bc2f070a80abfef9c_Interactive%20views.svg",
    },
    {
        title: "Project Management",
        description: "Project management is Task-driven. Create a Project and divide it into multiple assignable tasks. Maintain project activities, and create new templates and timesheets. Generate salary slips, sales invoices, and project costing. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1a5f6ca1ea3a75bed7_Data%20control%20and%20access%20control.svg",
    },
    {
        title: "Time Tracking",
        description: "Record of hours an employee spends on completion of each task. Calculate the amount billable towards an employee, even if they are working on multiple tasks. Add the type of activity and tag the total cost amount.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c192101a13d0bed2a1a_Search%20Filters.svg",
    },
    {
        title: "Maintain Quality",
        description: "Set quality goals, procedures, reviews, feedback, and meetings to ensure a smooth operation and monitoring of a quality control plan. Keep a close eye on the quality control strategy and uphold high standards.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1861d24b22144906ad_Global%20search%20bar.svg",
    },
    {
        title: "Quality Inspection",
        description: "Mark incoming or outgoing products for quality inspection. Create a quality inspection template to add inspection parameters and acceptance criteria, a job card to monitor the quality of in-process items, and more.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1961d24bceb74906ae_Easy%20Duplication%20of%20unique%20settings.svg",
    },
    {
        title: "Master Reports",
        description: "Get access to the ‘tree of procedures’ and track the project's profitability to ensure that you don't overspend. Analyze the billing per project and employee between a specific period and of all the stock movements.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c197a31d35b573d886f_Unique%20Procedure%20Layouts.svg",
    },

]
const content4 = [
    {
        title: "Timely Analytics",
        description: "Access data faster and use robust analytics to assess it as you go. Business projections may be revised using robust, real-time analysis through number cards and charts provided via interactive reports and dashboards.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d2230385803682f896016_majesticons_analytics%20(1).svg",
    },
    {
        title: "Configurable Dashboards",
        description: "With real-time access to data on sales, earnings, and opportunities, a customizable ERP dashboard helps simplify management process. Information like monthly sales, cash flow forecasts, and gross profits is only a click away.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d22511a3a0381131f1562_ci_dashboard.svg",
    },
    {
        title: "Customizable Reports",
        description: "Get access to pre-defined graph reports in the dashboard. Choose what you want to view on these fully customizable reports and also configure the metrics on which the reports are shown.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d205be5bd5a794ddd6202_Master%20Reports.svg",
    },
    {
        title: "Number Cards and Charts",
        description: "With the number card you will be able to get the total count of ongoing job card and work orders which are open, in-progress, and completed. Charts provide information about the total quantity produced.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d2263b6d5d57f7c98719c_CC%201%20(2).svg",
    },
    {
        title: "Exponential smoothing",
        description: "Use the exponential smoothing method to calculate the forecast based on past sales order/delivery notes/quotation data. Apply filters to extract specific data and predict the forecast of upcoming period data.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d22b0a239391c5b1f3373_ant-design_filter-filled.svg",
    },
    {
        title: "Bird-eye View",
        description: "Get access to reports that are easy to understand. Keep close tabs on all the financial flows and evaluate performance. View new charts for on-the-fly data analysis using Kanban, List, Gantt, Calendar and Tree view lists. ",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d22d76b94ff728477844c_teenyicons_kanban-solid.svg",
    },

]

const MainTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '1rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#474d66'
});

const SubTitle = styled(Typography)({
    fontSize: '1.25rem',
    marginBottom: '2rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#8f95b2',


});

const CallToAction = styled(Button)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    borderRadius: '100px',
    fontFamily: '"Poppins", sans-serif !important',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#585bd5',
        border: '2px solid #585bd5',
        boxShadow: 'none',
        fill: '#585bd5',
    },
});
const HeroContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gridRowGap: '30px',
    width: '100%', 
    maxWidth: '1000px',
    margin: '0 auto',
    maxHeight:'300px'
  }));

const HeroLogo = styled(Box)({
    display: 'inline-block',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '25px',
    marginBottom: '20px',
    marginTop: '20px',

});
const HeroButton = styled(Button)({
    height: '60px',
    width: '160px',
    borderRadius: '100px !important',
    /* padding: 8px 13px; */
    transition: '0.2s',
    textTransform: 'none!important',
    boxShadow: 'none'

});
const ExploreButton = styled(Button)({
    lineHeight: 1.5, fontWeight: 500, backgroundColor: '#fff', border: '1px solid #d6d6d8', textTransform: 'none !important', padding: '1rem 1rem', width: '220px', borderRadius: '4rem', marginTop: '20px !important', fontFamily: '"Poppins", sans-serif !important', fontSize: '16px',
    '&:hover': {
        background: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }

})

const MainTypo = styled(Typography)({
    color: '#fff',
    fontWeight: 500,
    lineHeight: 1.3,
    fontFamily: 'poppins, sans-serif !important '
})

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});


const useStyles = makeStyles((theme) => ({
    image: {
        [theme.breakpoints.up('md')]: {
            width: '650px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image1: {
        [theme.breakpoints.up('md')]: {
            width: '700px !important', height: '730px !important', borderRadius: 0, objectFit: 'cover', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image2: {
        [theme.breakpoints.up('md')]: {
            width: '650px !important', height: '680px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image3: {
        [theme.breakpoints.up('md')]: {
            width: '650px !important', height: '650px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image4: {
        [theme.breakpoints.up('md')]: {
            width: '750px !important', height: '600px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
        }
    },
    imageContainer1: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    imageContainer2: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '70% !important',
            aspectRatio: '1.84',
            paddingTop: '2% !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
            alignItems: 'center'


        }

    },
    imageContainer3: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '70% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    imageContainer4: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
            height: '100% !important',
        }
    },
    gridRoot: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot1: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot2: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot3: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '30% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot4: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridContent: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            color: '#6D5DF6',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '350px !important', // Set height
            textAlign: 'center',
            justifyContent: 'center', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            maxWidth: '100%',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#0080e5',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '280px !important', // Set height
            textAlign: 'center',
            justifyContent: 'flex-start', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 0 18px -4px rgba(177,177,177,.7)',
            width: '100% !important'
            // '&:hover': {
            //     boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            // }
        }
    },
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80% !important',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '25.333% !important',
        }
    },

}));



const Erp: React.FC = () => {
    const classes = useStyles();
    const { ref: containerRef, inView: containerInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef1, inView: containerInView1 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef2, inView: containerInView2 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef3, inView: containerInView3 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef4, inView: containerInView4 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRefHero, inView: containerHero } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });


    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ margin: '2% auto', maxWidth: '100% !important', padding: '0 !important',

                marginLeft:{
                    xs:"-10px"
                }
             }}>
            <Grid 
                container 
                spacing={4} 
                alignItems="center" 
                sx={{ 
                    maxWidth: '90%', 
                    margin: '0 auto 40px', 
                    display: { xs: 'flex', md: 'flex' }, 
                    alignItems: 'flex-start', 
                    minHeight: '80vh', 
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row' } 
                }}
            >
                <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    sx={{ 
                    maxWidth: { xs: '100%', md: '45%' }, 
                    paddingTop: { xs: '20px', md: '55px' }, 
                    paddingLeft: { xs: '16px', md: '0' }, 
                    minHeight: '100%' ,
                    }}
                >
                    <MainTitle 
                    sx={{ 
                        textAlign: { xs: 'center', md: 'left' }, 
                        wordBreak: 'break-word', 
                        fontSize: { xs: '28px', md: '6vh' }, 
                        letterSpacing: '-1.5px' 
                    }}
                    >
                    Easily Scale Your Business With An All-In-One 
                    <Typography 
                        component="span" 
                        sx={{ 
                        fontSize: { xs: '24px', md: '6vh' }, 
                        color: '#f1352c', 
                        fontWeight: 'bold' 
                        }}
                    >
                        ERP Software
                    </Typography>
                    </MainTitle>
                    
                    <SubTitle sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    Automate your finances, manage warehouse, HR, and projects, and lead the business world with ease.
                    </SubTitle>

                    <Box
                                    textAlign="center"
                                    mb={4}
                                    sx={{
                                        margin: { xs: '10px 0', md: '32px 0' },
                                        width: { xs: '100%', sm: '80%', md: '70%' },
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: 'space-around',
                                    }}
                                    >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        lineHeight: '24px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#0080ef',
                                        border: '1px solid #d6d6d8',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': {
                                            backgroundColor: '#0070d4',
                                             transform:'scale(0.9)'
                                        },
                                        }}
                                    >
                                        Explore Demo Login
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href="/request-demo"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        lineHeight: 1.5,
                                        border: '2px solid #0080e5',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': { background: '#edeff5', transform:'scale(0.9)' },
                                        }}
                                    >
                                        Book a Demo
                                    </Button>
                                    </Box>
                </Grid>
  
                <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    sx={{ 
                    borderRadius: '12px', 
                    maxWidth: { xs: '100%', md: '55%' }, 
                    paddingTop: { xs: '20px', md: '0' }, 
                    paddingLeft: { xs: '0', md: '14px' }, 
                    textAlign: 'center',
                    height: 'auto' 
                    }}
                >
                    <img 
                    src={erp} 
                    alt="ERP software" 
                    style={{ 
                        width: '100%', 
                        height: 'auto', 
                        maxWidth: { xs: '100%', md: '650px' }, 
                        borderRadius: '12px' 
                    }} 
                    />
                </Grid>
            </Grid>



                <Grid ref={containerRef} container spacing={4} alignItems="flex-start" sx={{ 
                    minHeight: {xs:'120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '80vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            HR, Employee Lifecycle and Payroll Management
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif' ,marginTop:{
                            xs:'20px',md:'0px'
                        }}}>
                            Automate onboarding, tax, and salary-related processes in one click. Perform all human resource management functions, set up employee details with their designation, create salary grades and seamlessly generate salary slips.
                        </Typography>
                        <ExploreButton className={containerInView ? 'expand-animation' : ''} 
                            sx={{ margin: { xs: '10px auto', md: '0' }, color: '#0080e5',marginLeft:{
                                xs:'-2px',md:'0px'
                            } }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid >
                        <img src={hrr}             style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '600px', 
                            display: 'block',
                            margin: '0 auto',
                            }} 
                          alt = "image1"  
                        />
                    </Grid>
                </Grid>
                <Grid  container
                    spacing={1}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}
                >
                    {content.map((content, index) => (
                    <Grid 
                        item 
                        xs={12} 
                        md={4}   
                        key={index} 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',  
                            maxWidth: { md: '25.333% !important' },
                        }}
                    >
                        <Box sx={{
                                backgroundColor: '#fff',  
                                padding: '16px',         
                                borderRadius: '8px',     
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                display: 'flex',
                                flexDirection: 'column', 
                                alignItems: 'center',   
                                width: { xs: '80%', md: '100%' },  
                            }}>
                            <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "image1" />
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef1} container spacing={4} alignItems="flex-start" sx={{ minHeight: '140vh', maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-between' }, maxHeight: '100vh', background: '#f9a400', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item className={classes.imageContainer1}
                        sx = {{
                            marginLeft:{
                                xs : '-20px',
                                md:'70px'
                            }
                        }}
                    >
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d1bd7d7d36d62c647f8f1_Cards%2C%20Graph%2C%20Transaction.svg"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '600px', 
                                        display: 'block',
                                        margin: '0 auto',
                                      }}
                            alt = "image2"
                        />
                    </Grid>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Financial Management
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif' 
                            ,marginTop:{
                                xs:'20px',
                                md:'0px'
                            }
                        }}>
                            Simplify financial operations that boost workflow automation, reduce inefficiencies, and motivate more profitable decision-making. Analyze your business decisions with real-time reporting and save time so you can steer strategically.                         </Typography>
                        <ExploreButton className={containerInView1 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f9a400' }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>

                </Grid>
                <Grid  container
                    spacing={2}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}
                >
                    {content1.map((content, index) => (
                        <Grid  item 
                            xs={12} 
                            md={4}   
                            key={index} 
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',  
                            maxWidth: { md: '25.333% !important' },
                            }}
                        >
                            <Box sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}
                            >
                                <img style={{ textAlign: 'center', margin: '0 auto', color: '#f9a400' }} src={content.image} alt = "image2" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#f9a400', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef2} container spacing={4} alignItems="flex-start" 
                    sx={{ minHeight:{xs:'150vh',md: '140vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#008c44', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot2}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Buying and Warehouse Management
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '18px', color: '#fff' ,marginTop:{xs:'20px',md:'0px'}}}>
                            Maintain custom warehouses by planning, managing, executing, monitoring, and reporting on all related processes. Understand purchase trends and buying processes. Adapt to the unique requirements of each industry, including manufacturing, retail, and food production.
                        </Typography>
                        <ExploreButton className={containerInView2 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#008c44' }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid>
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d1d6b9150bf61258fd86d_warehouse.png"            style={{
                        width: '100%',
                        height: 'auto',
                        maxWidth: '600px', 
                        display: 'block',
                        margin: '0 auto',
                        }} 
                       alt = "image3" 
                    />
                    </Grid>
                </Grid>
                <Grid container spacing={1} className="container-grid" sx={{
                marginTop: { xs: '-40px', md: '-120px' },
                justifyContent: 'center', 
                textAlign: 'center',
                alignItems: 'stretch',
                }}>
                    {content2.map((content, index) => (
                        <Grid item 
                            xs={12} 
                            md={4}   
                            key={index} 
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',  
                            maxWidth: { md: '25.333% !important' },
                            }} 
                        >
                            <Box sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}
                            >
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "image3" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#008c44', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef3} container 
                spacing={4} alignItems="flex-start" 
                sx={{ minHeight: {xs:'130vh',md:'130vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f1352c', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid sx = {{marginLeft:{xs : '0px',md:'20px'}}}>
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d1e61b6d5d5a87e983ec8_Project%20management%20(1).svg"           style={{
                        width: '100%',
                        height: 'auto',
                        maxWidth: '600px', 
                        display: 'block',
                        margin: '0 auto',
                        }}
                        alt = "image4"
                        />
                    </Grid>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Project Management and Quality Checks
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif',
                            marginTop:{
                                xs:'20px',
                                md:'0px'
                            }
                         }}>
                            Discover project and task management with complete integration of billing and costing. Create a timesheet based on actual time spent on a task. Track the profitability of a project to ensure that you don't overspend and maintain quality with goals, tracking, and feedback.
                        </Typography>
                        <ExploreButton className={containerInView3 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f1352c' }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>

                </Grid>
                <Grid container spacing={1} className="container-grid"
                    sx={{
                        marginTop: { xs: '-40px', md: '-120px' },
                        justifyContent: 'center', 
                        textAlign: 'center',
                        alignItems: 'stretch',
                      }}
                
                >
                    {content3.map((content, index) => (
                            <Grid 
                            item 
                            xs={12} 
                            md={4}   
                            key={index} 
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',  
                            maxWidth: { md: '25.333% !important' },
                            }}
                        >
                            <Box               
                                sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}
                            >
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }}  alt = "image4" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#f1352c', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef4} container spacing={4} alignItems="flex-start" sx={{ minHeight: '120vh', maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '120vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' },
                    marginLeft:{xs : '0px',md:'25px'}
                }} className={classes.gridRoot4}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Analytical Reporting
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif' }}>
                            Whether about your purchase, procurement, stock, or sales, create consolidated reports, display progress in all master and key reports, and make better decisions for your business.
                        </Typography>
                        <ExploreButton className={containerInView4 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#0080e5' }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid >
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/630d1e93639254e8c0aae396_Analytic%20report.svg" 
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '700px', 
                                        display: 'block',
                                        margin: '0 auto',
                                      }}
                                      alt = "imag5"
                        />
                    </Grid>
                </Grid>
                <Grid container
                    spacing={2}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}>
                    {content4.map((content, index) => (
                        <Grid 
                        item 
                        xs={12} 
                        md={4}   
                        key={index} 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',  
                          maxWidth: { md: '25.333% !important' },
                        }}
                      >
                            <Box  
                                sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}
                            >
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "image6" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >
            </Container >
            <Container
          sx={{
            marginTop: '0 !important',
            minHeight: '50vh', 
            height: 'auto',
            paddingTop: { xs: '0px', md: '10px', lg: '10px' },
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: { xs: '0 auto', md: '0 auto !important' },
            width:'1240px !important',
            maxWidth:'100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={containerRefHero}
        >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '55vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>
        <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-60px',  
                    lg: '-80px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
            </Container>
        </ThemeProvider>


    );
};

export default Erp;



