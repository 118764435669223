import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Box
} from '@mui/material';
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material';
import waveimage from "../images/2ndwaveimage.png";

interface FormValues {
  name: string;
  email: string;
  mobileNo: string;
  companyName: string;
  message: string;
}

const initialFormValues: FormValues = {
  name: '',
  email: '',
  mobileNo: '',
  companyName: '',
  message: ''
};

const ReminderContactus: React.FC = () => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: Partial<FormValues> = {};
    if (!formValues.name) newErrors.name = 'Please enter your name';
    if (!formValues.email || !/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = 'Please enter a valid email';
    if (!formValues.mobileNo || formValues.mobileNo.length < 10) newErrors.mobileNo = 'Please enter a valid phone number';
    if (!formValues.companyName) newErrors.companyName = 'Please enter a subject';
    if (!formValues.message) newErrors.message = 'Please enter your message';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Simulate form submission
    try {
      const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
        cache: 'no-cache',
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
         ...formValues,
        })
      });

      if (response.ok) {
        setSuccessMessage('Your request has been submitted successfully. Our support team will contact you soon.');
        setFormValues(initialFormValues);
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box 
      sx={{ 
        background: `url(${waveimage})`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        width: '100%', 
        py: 2 
      }}
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start' 
            }}
          >
            <Typography 
              variant="h2" 
              sx={{ 
                mb: 2, 
                fontSize: { xs: '24px', sm: '28px', md: '32px' }, 
                fontWeight: '1000', 
                textAlign: { xs: 'center', sm: 'left' } 
              }}
            >
              Ready To Boost Your Business Online?
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 2, 
                letterSpacing: '1px', 
                fontSize: { xs: '12px', sm: '14px', md: '15px' }, 
                textAlign: { xs: 'center', sm: 'left' } 
              }}
            >
              Request a Free Quote & Tell Us About Your Project. We Help Build Your Road To Success.
            </Typography>
            <Typography 
              sx={{ 
                fontSize: { xs: '16px', sm: '18px', md: '20px' }, 
                textAlign: { xs: 'center', sm: 'left' } 
              }}
            >
              Give Us a Call
            </Typography>
            <Box sx={{ mt: 2, textAlign: { xs: 'center', sm: 'left' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                <PhoneIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <a href="tel:+91 99401 81721" style={{ textDecoration: 'none' }}>+91 98864 84831</a>
                </Typography>
              </Box>
              <hr style={{ width: '100%', maxWidth: '580px', margin: 'auto' }} />
              <Typography sx={{ mt: 2, fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
                Help Desk
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <a href="mailto:info@fusionerp.in" style={{ textDecoration: 'none' }}>info@fusionerp.in</a>
                </Typography>
              </Box>
              <hr style={{ width: '100%', maxWidth: '580px', margin: 'auto', marginTop: '20px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: 2, boxShadow: 3, width: '100%' }}>
              <CardContent>
                <Typography 
                  variant="h4" 
                  align="center" 
                  sx={{ 
                    mb: 2, 
                    fontWeight: '1000', 
                    fontSize: { xs: '24px', sm: '26px', md: '30px' } 
                  }}
                >
                  Let's create something new!
                </Typography>
                <Typography 
                  variant="body1" 
                  align="center" 
                  sx={{ 
                    mb: 3, 
                    fontSize: { xs: '12px', sm: '14px', md: '16px' }, 
                    textAlign: 'center' 
                  }}
                >
                  Your new digital platform starts here. Tell us your dream and
                  <br />
                  <span style={{ marginRight: '100px' }}>
                    make it real.
                  </span>
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Your name"
                        name="name"
                        value={formValues.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        name="mobileNo"
                        value={formValues.mobileNo}
                        onChange={handleChange}
                        error={!!errors.mobileNo}
                        helperText={errors.mobileNo}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Subject"
                        name="companyName"
                        value={formValues.companyName}
                        onChange={handleChange}
                        error={!!errors.companyName}
                        helperText={errors.companyName}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Questions"
                        name="message"
                        multiline
                        rows={4}
                        value={formValues.message}
                        onChange={handleChange}
                        error={!!errors.message}
                        helperText={errors.message}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Submit
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReminderContactus;
