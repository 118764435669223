import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';

import oneabout from '../images/oneabout.jpg';
import aboutone from '../images/Onehasabout1stRename.jpg';
import abouttwo from '../images/aboutus1st.png';

import mission from "../images/mission-01.svg";
import values from "../images/value-01-01.svg";
import pledge from "../images/pledge-01.svg";

import ReminderContactus from './ReminderContactus';

// Styled components
const ImageContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
}));

const StyledImage = styled('img')({
  width: '100%',
  height: 'auto',
  maxHeight: '300px',
  objectFit: 'cover',
});

const TextOverlay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
  fontSize: '2rem',
  fontWeight: 'bold',
  textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
}));

const FusionerpAbout = () => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ImageContainer>
              <StyledImage src={oneabout} alt="About Us" />
              <TextOverlay sx={{ fontSize: '70px', fontWeight: 1000 }}>
                About Us
              </TextOverlay>
            </ImageContainer>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box 
                sx={{ overflow: 'hidden', marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
                <img
                  src={aboutone}
                  alt="Our Story"
                  style={{
                    width: '90%',
                    maxWidth: '500px',
                    height: 'auto',
                    maxHeight: '400px',
                    display: 'block',
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  marginTop: { xs: 4, md: 6 },
                  paddingX: { xs: 4, sm: 4, md: 6 },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: '12px', md: '14px' },
                    letterSpacing: '3px',
                    color: '#007ee5',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  OUR STORY
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.25rem', sm: '1.5rem', lg: '2rem' },
                    fontWeight: 1000,
                    animation: 'zoomIn 1s 500ms forwards',
                    marginTop: 2,
                    color: '#007ee5',
                    lineHeight: { xs: '1.2', sm: '1.33' },
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  Who We Are?
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    animation: 'fadeInUp 1s 600ms forwards',
                    lineHeight: { xs: '1.4rem', sm: '1.5rem' },
                    color: 'GrayText',
                    fontSize: { xs: '12px', sm: '14px' },
                    fontWeight: '600',
                    textAlign: { xs: 'center', md: 'left' },
                    whiteSpace: 'normal',
                  }}
                >
                  At Ciphercode Tech Solutions, we are a dedicated professional software development company specializing in creating and supporting a wide range of online business platforms that exceed expectations. We apply cutting-edge technology to build powerful web applications, helping startups and SMEs achieve their digital market goals and surpass their competitors.
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    animation: 'fadeInUp 1s 600ms forwards',
                    lineHeight: { xs: '1.4rem', sm: '1.5rem' },
                    color: 'GrayText',
                    fontSize: { xs: '12px', sm: '14px' },
                    fontWeight: '600',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  With years of experience and a commitment to staying ahead of the latest technology trends, we have evolved to deliver and support the online world. Our strong technical team, combined with friendly partnerships, enables us to offer top-notch software services to clients globally.
                </Typography>
              </Box>
            </Grid>




            {/* <Grid item xs={12} md={6}>
              <Box sx={{ marginTop: 6 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '14px',
                    letterSpacing: '3px',
                    color: '#007ee5',
                  }}
                >
                  OUR STORY
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 1000,
                    animation: 'zoomIn 1s 500ms forwards',
                    marginTop: 2,
                    color: '#007ee5',
                    lineHeight: '1.33',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '1.25rem',
                    },
                  }}
                >
                  Who We Are?
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    animation: 'fadeInUp 1s 600ms forwards',
                    lineHeight: '1.5rem',
                    color: 'GrayText',
                    fontSize: '14px',
                    fontWeight: '600',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  At Ciphercode Tech Solutions, we are a dedicated professional software development company specializing in creating and supporting a wide range of online business platforms that exceed expectations. We apply cutting-edge technology to build powerful web applications, helping startups and SMEs achieve their digital market goals and surpass their competitors.
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    animation: 'fadeInUp 1s 600ms forwards',
                    lineHeight: '1.5rem',
                    color: 'GrayText',
                    fontSize: '14px',
                    fontWeight: '600',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  With years of experience and a commitment to staying ahead of the latest technology trends, we have evolved to deliver and support the online world. Our strong technical team, combined with friendly partnerships, enables us to offer top-notch software services to clients globally.
                </Typography>
              </Box>
            </Grid> */}


          </Grid>
        </Grid>
      </Box>

      {/* 2nd section images */}
      <Box sx={{ flexGrow: 1, padding: 0 }}>
        <Grid container spacing={0} sx={{ marginTop: 4, backgroundColor: '#E8F4F7', width: '100%', padding: '20px 0', boxSizing: 'border-box' }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '20px' }}>
            <Box sx={{ marginBottom: '20px', textAlign: 'left', padding: '0 20px' }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#007ee5',
                  animation: 'zoomIn 1s forwards',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '1.25rem',
                  },
                }}
              >
                PROVEN INDUSTRY <span style={{ color: '#689f38' }}>LEADERSHIP</span>
              </Typography>
              <Box
                sx={{
                  animation: 'fadeInUp 1s 400ms forwards',
                  marginTop: 2,
                }}
              >
                <Typography
                  sx={{
                    lineHeight: '1.5rem',
                    color: 'GrayText',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: '600',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  Over the years, dozens of clients have sought us out for their most challenging projects. At Ciphercode Tech Solutions, we thrive on taking on these challenges, turning the impossible into reality by designing and developing innovative software solutions that truly reflect your business.
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5rem',
                    color: 'GrayText',
                    textAlign: 'left',
                    marginTop: 2,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  We prioritize listening carefully to your needs, delivering customer-centric solutions for websites and applications that enhance audience engagement and boost sales. Client satisfaction is our top priority, making us the smart choice for those seeking the best software development companies. If you're looking for excellence, look no further!
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: 0 }}>
            <Box
              sx={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                padding: 0,
              }}
            >
              <img
                src={abouttwo}
                alt="Proven Industry Leadership"
                style={{
                  width: '90%',
                  maxWidth: '400px',
                  height: 'auto',
                  maxHeight: '400px',
                  objectFit: 'cover',
                  display: 'block',
                  margin: 0,
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Cards Section */}
        <Box sx={{ padding: '20px 20px', backgroundColor: '#F9F9F9' }}>
          <Grid container spacing={4} justifyContent="center">
            {/* Card 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '20px',
                  border: '1px solid #ddd',
                  borderRadius: '28px',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  height: 'auto',
                  width: '100%',
                  maxWidth: '323px',
                  margin: 'auto',
                }}
              >
                <img
                  src={mission}
                  alt="Mission"
                  style={{
                    width: '60px',
                    height: 'auto',
                    marginBottom: '10px',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: '1000',
                    marginBottom: '10px',
                    fontSize: '20px',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  Our Mission
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'GrayText',
                    lineHeight: '1.5rem',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  To deliver innovative and high-quality tech solutions that drive business growth and success.
                </Typography>
              </Box>
            </Grid>

            {/* Card 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '20px',
                  border: '1px solid #ddd',
                  borderRadius: '28px',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  height: 'auto',
                  width: '100%',
                  maxWidth: '323px',
                  margin: 'auto',
                }}
              >
                <img
                  src={values}
                  alt="Values"
                  style={{
                    width: '60px',
                    height: 'auto',
                    marginBottom: '10px',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: '1000',
                    marginBottom: '10px',
                    fontSize: '20px',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  Our Values
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'GrayText',
                    lineHeight: '1.5rem',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  Integrity, Innovation, and Excellence are the core principles driving our work.
                </Typography>
              </Box>
            </Grid>

            {/* Card 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '20px',
                  border: '1px solid #ddd',
                  borderRadius: '28px',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  height: 'auto',
                  width: '100%',
                  maxWidth: '323px',
                  margin: 'auto',
                }}
              >
                <img
                  src={pledge}
                  alt="Pledge"
                  style={{
                    width: '60px',
                    height: 'auto',
                    marginBottom: '10px',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: '1000',
                    marginBottom: '10px',
                    fontSize: '20px',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  Our Pledge
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'GrayText',
                    lineHeight: '1.5rem',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '12px',
                    },
                  }}
                >
                  Committed to providing exceptional service and support to all our clients.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      
      <ReminderContactus />
    </>
  );
};

export default FusionerpAbout;
