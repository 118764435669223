import React from 'react';
import {FormControlLabel, Typography, Button, Container, Card, CardContent, CardActions, Paper, Switch as MuiSwitch } from '@mui/material';
import { styled } from '@mui/system';
import Lighting from "../images/onehas_Lightning.png";
import Damiond from "../images/onehas_Diamond.png";
import checkIcon from "../images/onehastick.png";
import Box from "@mui/material/Box";
import { useState } from 'react';
import saveonehas from '../images/onehassave.svg';
import { useNavigate } from 'react-router-dom';
import whitetickimages from "../images/white_tick2.png";

const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    borderRadius: '50px',
  },
  '& .MuiSwitch-thumb': {
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: '50px',
  },
}));

const SaveImage = styled('img')({
    position: 'absolute',
    left: 'calc(50% - 90px)',
    top: '290px',
    transform: 'translateX(-100%)',
    width: '90px',
    height: 'auto',
});
const BillingOptions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginTop: '0px auto'
});

const Plan = styled(Card, { shouldForwardProp: (prop) => prop !== 'isSelected' })(
  ({ isSelected, isTeamPlan, theme }) => ({
    width: '100%',
    maxWidth: '380px',
    height: 'auto',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    border: isSelected ? '2px solid #007ee5' : 'none',
    transform: isSelected ? 'scale(1.05)' : 'none',
    backgroundColor: isTeamPlan ? '#007ee5' : isSelected ? '#f0f9ff' : '#fff',
    color: isTeamPlan ? '#fff' : isSelected ? '#007ee5' : '#000',
    position: 'relative',
    '& .MuiTypography-root': {
      color: isTeamPlan ? '#fff' : isSelected ? '#A3AFB9' : '#000',
    },
    '& .MuiChip-root': {
      color: isTeamPlan ? '#007ee5' : isSelected ? '#007ee5' : '#000',
      backgroundColor: isTeamPlan ? '#fff' : isSelected ? '#f0f9ff' : '#fff',
    },
    '& .FeatureItem': {
      color: isTeamPlan ? '#fff' : isSelected ? '#007ee5' : '#000',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '15px',
    },
  })
);

const CenteredCardActions = styled(CardActions)({
  justifyContent: 'center',
});

const FeatureList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '10px',
});

const FeatureItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
  color: '#656b7d',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '500',
  lineHeight: '1.2',
  gap: '10px',
});

const BillingSwitchWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});


const FusionerpCrmScription = () => {
    const [billingOption, setBillingOption] = useState('monthly');
    const [price, setPrice] = useState(49);

    const handleBillingChange = (event) => {
        const newBillingOption = event.target.checked ? 'monthly' : 'yearly';
        setBillingOption(newBillingOption);
        setPrice(newBillingOption === 'monthly' ? 49 : 39);
    };
  const navigate = useNavigate();

    const handleClick = (plan,price) => {
      navigate(`/signup?defaultValue=ERPNEXT&plan=${encodeURIComponent(plan)}&price=${price}`);
    };
  return (
    <Container>

<Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1,
                        flexDirection: {
                            xs: 'column', 
                            sm: 'row',   
                        },
                        px: { xs: 2, sm: 3 },
                    }}
                >
                    <SaveImage
                        src={saveonehas}
                        alt="Save Image"
                        sx={{
                            mb: { xs: 2, sm: 0 }, 
                            mt: { xs: 11, sm: 0,md:2 },
                            width: {
                                xs: '25%', 
                                sm: 'auto',
                            },
                            maxWidth: '200px',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            mt: { xs: 1, sm: 0 },
                            ml: { xs: 0, sm: 'auto' }, 
                            mr: { xs: 2, sm: 0 },
                        }}
                    >
                        <FormControlLabel
                            control={
                                <MuiSwitch
                                    checked={billingOption === 'monthly'}
                                    onChange={handleBillingChange}
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: 'white',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: '#007ee5',
                                        },
                                    }}
                                />
                            }
                            label={billingOption === 'monthly' ? 'Billed Monthly' : 'Billed Yearly'}
                            sx={{
                                typography: {
                                    xs: 'caption', 
                                    sm: 'body1',
                                },
                            }}
                        />
                    </Box>
                </Box>
      <Paper elevation={0} sx={{ padding: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <Plan isTeamPlan style={{ height: 'auto', backgroundColor: '#007ee5', color: 'white', border: '3px solid white' }}>
            <CardContent>
              <Paper elevation={8} sx={{
                position: 'absolute',
                top: '-4px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                color: 'blue',
                padding: '5px 8px',
                borderRadius: '5px',
                fontSize: '14px',
                fontWeight: 'bold',
                textAlign: 'center',
                zIndex: 1,
              }}>
                Limited Time Offer
              </Paper>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box>
                  <img
                    src={Lighting}
                    alt="Advanced Plan"
                    style={{ width: '70px', height: '70px', marginLeft: '-20px' }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: 'white !important',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '20px',
                      fontWeight: 700,
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                      marginBottom: '15px',
                      marginLeft: '12px',
                    }}
                  >
                    Team Plan<br />
                    Free
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px', color: 'white !important' }}>
                  ${price}/month
                </Typography>
                </Box>
              </Box>
              <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
              <FeatureList sx={{ fontSize: '12px', fontWeight: 500 }}>
                <FeatureItem sx={{ color: 'white !important' }}><img src={whitetickimages} alt="tick" />ERP</FeatureItem>
                <FeatureItem sx={{ color: 'white !important' }}><img src={whitetickimages} alt="tick" />Shared/Private Cloud</FeatureItem>
                <FeatureItem sx={{ color: 'white !important' }}><img src={whitetickimages} alt="tick" />Chat/Email support</FeatureItem>
                <FeatureItem sx={{ color: 'white !important' }}><img src={whitetickimages} alt="tick" />Configured Solution</FeatureItem>
              </FeatureList>
            </CardContent>
            <CenteredCardActions>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '30px',
                  fontSize: '14px',
                  fontWeight: 700,
                  height: '50px',
                  minWidth: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 20px',
                  textTransform: 'none',
                  backgroundColor: 'white !important',
                  color: '#005bb5',
                  transform: 'scale(1)',
                  transition: 'transform 0.3s ease-in-out', 
                  '&:hover': {
                    transform: 'scale(0.95)',
                  }
                }}
                onClick={() => handleClick('TeamPlan', price)}
              >
                Get Started
              </Button>
            </CenteredCardActions>
          </Plan>
          <Plan style={{ height: 'auto' }}>
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box>
                  <img
                    src={Damiond}
                    alt="Individual Plan"
                    style={{ width: '70px', height: '70px', marginLeft: '-20px' }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#01178e !important",
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '16px',
                      fontWeight: 1000,
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                      marginBottom: '20px',
                      marginLeft: '12px',
                    }}
                  >
                      Enterprise Plan
                  </Typography>
                  <Typography sx={{ fontWeight: 1000, fontSize: '18px', textAlign: 'left', marginTop: '-20px', marginLeft: '10px' }}>
                      Contact Us
                  </Typography>
                </Box>
              </Box>
              <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: '20px 0', width: '100%' }} />
              <FeatureList sx={{ fontSize: '12px', fontWeight: 500 }}>
                <FeatureItem><img src={checkIcon} alt="tick" />Audit Logs </FeatureItem>
                <FeatureItem><img src={checkIcon} alt="tick" />Dedicated Cloud Server</FeatureItem>
                <FeatureItem><img src={checkIcon} alt="tick" />Dedicated Account Manager</FeatureItem>
                <FeatureItem><img src={checkIcon} alt="tick" />Customized Solution</FeatureItem>
              </FeatureList>
            </CardContent>
            <CenteredCardActions>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '30px',
                  fontSize: '14px',
                  fontWeight: 700,
                  height: '50px',
                  minWidth: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 30px',
                  textTransform: 'none',
                  transform: 'scale(1)',
                  transition: 'transform 0.3s ease-in-out', 
                  '&:hover': {
                    transform: 'scale(0.95)',
                  }
                }}
                onClick={() => navigate('/contact')}
              >
                Talk to Sales
              </Button>
            </CenteredCardActions>
          </Plan>
        </Box>
      </Paper>
    </Container>
  );
};

export default FusionerpCrmScription;
