import { Box, Typography, Rating, Button } from '@mui/material';
import capeture from "../images/onehas_capterra-logo.svg";
import software from "../images/software_newsft.png";
import growd from "../images/onehas_g2crowd-logo.svg";
import getapp from "../images/onehas_getapp-logo.svg";
import questionmark from "../images/questionmark.png";
import { useNavigate } from 'react-router-dom';


const Contactonehas = () => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: { xs: '8px', sm: '16px', md: '24px', lg: '32px' },
            }}
        >
            <Box
                sx={{
                    width: { xs: '90vw', sm: '95vw', md: '80vw', lg: '90vw' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    padding: '16px',
                    border: '2px solid rgba(0,126,229,.42)',
                    marginTop: { xs: '70px', sm: '75px', md: '100px' },
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                   
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        gap: { xs: '16px', sm: '24px' },
                        flexGrow: 1,
                    }}
                >
                    <img
                        src={questionmark}
                        alt="question mark"
                        style={{ maxWidth: '60px', marginTop: '10px' }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
                        <Typography sx={{
                            fontSize: { xs: '20px', sm: '24px' },
                            fontWeight: 'bold',
                            color: '#007ee5',
                            fontFamily: 'Poppins, sans-serif',
                        }}>
                            Having Trouble Selecting a Plan?
                        </Typography>
                        <Typography sx={{
                            color: 'grey',
                            fontWeight: 600,
                            fontSize: { xs: '12px', sm: '14px' },
                        }}>
                            Our mission is to empower our client’s base with the best tools possible. Rest
                            <br />
                            assured, our sales executives are ready to assist you in choosing an optimal
                            <br />
                            plan that meets your needs.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            marginTop: { xs: '20px', sm: '50px' },
                            height: '55px',
                            width: { xs: '150px', sm: '200px' },
                            borderRadius: '10px',
                            fontWeight: '700',
                            textTransform: 'none',
                            fontSize:'15px'
                        }}
                        onClick={() => navigate('/contact')} 
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>
{/* 
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: { xs: '24px', sm: '32px', md: '48px', lg: '64px' },
                    marginTop: '40px',
                }}
            >
                {[{ img: capeture }, { img: software }, { img: growd }, { img: getapp }].map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            textAlign: 'center',
                            m: { xs: '10px', sm: '16px' },
                            flex: '1 1 auto',
                            maxWidth: { xs: '80px', sm: '100px' },
                        }}
                    >
                        <img
                            src={item.img}
                            alt={`rating-${index}`}
                            style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
                        />
                        <Typography component="legend" sx={{ visibility: 'hidden' }}>{item.label}</Typography>
                        <Rating
                            name={`rating-${index}`}
                            value={4.5}
                            precision={0.5}
                            readOnly
                            sx={{ fontSize: { xs: '1rem', sm: '1.3rem' } }}
                        />
                        <Typography
                            component="legend"
                            sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
                        >
                            4.5/5 Rating
                        </Typography>
                    </Box>
                ))}
            </Box> */}
        </Box>
    );
}

export default Contactonehas;
