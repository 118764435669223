import React from "react";
import { Typography, Container, Button, Box, Grid, Icon } from "@mui/material";
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Footer from "./Footerhcm";
import cc3 from '../images/crm-content-3.svg';
import './HRMS.css';
import { useInView } from 'react-intersection-observer';

const content = [
    {
        title: "Zero Lead Leakage",
        description: "Enable automatic capture and management of leads from all channels. Route them to appropriate teams, save lead demographic details, and ensure no lead leakage and lost opportunities.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/611143f9522ac102a2adcb6f_Data%20Enrichment.svg",
    },
    {
        title: "Monitor Sales Target",
        description: "Set key performance indicators and analyze sales based on territory, product, partners & salesperson. Set modules of automatic reminders to keep track of performance and desired target.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/611146fce78f602a2b665df7_Monitor%20Sales%20Target.svg",
    },
    {
        title: "Interactive Dashboards",
        description: "Using reports and dashboards, see objectives and accomplishments by territory. Zoom-in and Zoom-out feature comes in very handy to analyse customer requirements and deliver accordingly.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114705bb0a89001f581525_Interactive%20Dashboards.svg",
    },
    {
        title: "Sales Forecast",
        description: "Quantity based exponentially smooth sales forecast. Based on the sales projection, adjust your marketing strategy. Sales management helps in predicting market shifts hence reducing risk.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6111471a314973b7abf26421_Strategic%20Risk%20Analysis.svg",
    },
    {
        title: "Insightful Leaderboards",
        description: "Use the forecast summary to identify your top achievers and assess each sales team’s strength based on the goals they meet. Configure your forecasts according to your preferences.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6111472940b4ec2416e826f2_Insightful%20Leaderboards.svg",
    },
    {
        title: "Management of Global Business",
        description: "Manage your global divisions and companies from a single configurable platform. OneHash CRM supports major international currencies and languages for easy comprehension.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/611147317fddd57df027fd4f_Management%20of%20Global%20Business.svg",
    },

]
const content1 = [
    {
        title: "Coherent Management of Sales",
        description: "Generate sales reports, monitor the performance of your sales team, get valuable insights on deadlines and current deals in the pipeline. Save your time in closing each transaction.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b733149733bf6f2a235_Analyze%20your%20sales%20funnel%20and%20pipeline.svg",
    },
    {
        title: "Analyze your Sales Funnel and Pipeline",
        description: "Keep track of existing leads and deals in your sales funnel and pipeline. Analyze behavioral triggers and work on the opportunities that are more likely to get converted into a customer.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b733149733bf6f2a235_Analyze%20your%20sales%20funnel%20and%20pipeline.svg",
    },
    {
        title: "Opportunity by Campaign",
        description: "Integrate with omnichannel media platforms and analyze which platforms yield the most results. Focus on platforms that are lagging behind, and strategize for their improvement.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b7c11653a1ba498d526_opportunity.svg",
    },
    {
        title: "Track Campaigns",
        description: "Use analytics tools and boost social media campaigns and marketing through automation features, keeping a track of what fits best, who fits best and who hits best!",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114bdcd48cb811d5a4e12e_track%20Campaign.svg",
    },
    {
        title: "Customer Segmentation",
        description: "Make segments based on age, gender, purchasing patterns, and other factors to better understand. Customer segmentation helps in analyzing the time and season for launching lighting deals and offers.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114b9ff41b4602c7eae825_Customer%20Segmentation.svg",
    },
    {
        title: "Customer Churns Analytics",
        description: "Analyse why, where, and how you lost a deal. Analyze the stages and territories where opportunities are lost the most, and work hard in retaining them.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61114bb4ed9cf0750af4f096_CustomerChurn.svg",
    },

]
const content2 = [
    {
        title: "Automate Approvals",
        description: "Trigger notifications to the authorized person based on user roles and user permissions as soon as a requirement for approval drops in. Set, proper check and balance for quality improvement.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125930243f86583f386771_automate%20approval.svg",
    },
    {
        title: "Automatized SMS Campaigns",
        description: "Effectively manage time by putting your email marketing on autopilot. With powerful third-party applications like twilio, Slack, etc., run smooth SMS campaigns and enhance engagement across the web and messages.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592fc2f0700adcbfd42e_Automatized%20Emails%20and%20SMS%20Campaigns.svg",
    },
    {
        title: "Drip Campaigns",
        description: "Initiate email campaigns and send newsletters. Automate social media campaigns for Twitter, LinkedIn and WhatsApp, and enable customized web forms via Facebook campaigns for lead capturing",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592ee9d2e71bcbaeb6e7_Inbuilt%20omni-channel%20Integrations.svg",
    },
    {
        title: "Alerts and Notifications",
        description: "Set up subscription renewal reminders for all monthly/annual subscriptions. Get notifications and alerts about upcoming events and meetings, to never miss a follow up or deadline.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592dda6c0e54edb8c8a1_Alerts%20and%20Notifications.svg",
    },
    {
        title: "Automate your finances",
        description: "Connect payment processes to reconcile invoices and costs. Process reimbursement requests and expenses without any hassle of back and forth emailing to the accounts department.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592dce9869194ad0d715_automate%20your%20finance.svg",
    },
    {
        title: "Coherent Workflow",
        description: "Create To-Do lists and notes to never miss details about prospects and opportunities. Keep tabs on all the changes made in the dashboards and reports through logs. Add comments on important documents.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112592d78b6ff83b1e6ce57_Coherent%20workflow.svg",
    },

]
const content3 = [
    {
        title: "Interactive Views",
        description: "Configure and use different views for different doctypes. Use Kanban view, gantt view, and tree view for task segregation, deadlines management, and pictorial study of any doctype respectively.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1bc2f070a80abfef9c_Interactive%20views.svg",
    },
    {
        title: "Data Control and Access Control",
        description: "Manage designated roles and permissions, with hierarchy control. Segregate data according to the assigned roles and filter important information with special permissions.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1a5f6ca1ea3a75bed7_Data%20control%20and%20access%20control.svg",
    },
    {
        title: "Search Filters",
        description: "Apply filters in different reports to easily search details about leads, prospects, deals etc. Filter the records based on specific values for a particular field in a document. Set default filters using customized forms.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c192101a13d0bed2a1a_Search%20Filters.svg",
    },
    {
        title: "Global Search Bar",
        description: "Global search bar enable users to search on select fields across different modules and doctypes. It helps users to find information quickly and efficiently.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c1861d24b22144906ad_Global%20search%20bar.svg",
    },
    {
        title: "Easy Duplication of Unique Settings",
        description: "Create and replicate an account to numerous accounts. This easy duplication allows you to save time and effort by eliminating the need to personalize each one separately.",
        image: cc3,
    },
    {
        title: "Unique Procedure Layouts",
        description: "Manage sales for various products, locations, & partners. Page layouts permit users to view and access customized data while also allowing them to design their workflows.",
        image: "https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125c197a31d35b573d886f_Unique%20Procedure%20Layouts.svg",
    },

]



const MainTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '1rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#474d66'
});

const SubTitle = styled(Typography)({
    fontSize: '1.25rem',
    marginBottom: '2rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#8f95b2',


});

const CallToAction = styled(Button)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    borderRadius: '100px',
    fontFamily: '"Poppins", sans-serif !important',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#585bd5',
        border: '2px solid #585bd5',
        boxShadow: 'none',
        fill: '#585bd5',
    },
});

const HeroContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gridRowGap: '30px',
    width: '100%', 
    maxWidth: '1000px',
    margin: '0 auto',
    maxHeight:'300px'
  }));

const HeroLogo = styled(Box)({
    display: 'inline-block',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '25px',
    marginBottom: '20px',
    marginTop: '20px',

});
const HeroButton = styled(Button)({
    height: '60px',
    width: '160px',
    borderRadius: '100px !important',
    /* padding: 8px 13px; */
    transition: '0.2s',
    textTransform: 'none!important',
    boxShadow: 'none'
});

const ExploreButton = styled(Button)({
    lineHeight: 1.5, fontWeight: 500, backgroundColor: '#fff', border: '1px solid #d6d6d8', textTransform: 'none !important', padding: '1rem 1rem', width: '220px', borderRadius: '4rem', marginTop: '20px !important', fontFamily: '"Poppins", sans-serif !important', fontSize: '16px',
    '&:hover': {
        background: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }
})

const MainTypo = styled(Typography)({
    color: '#fff',
    fontWeight: 500,
    lineHeight: 1.3,
    fontFamily: 'poppins, sans-serif !important '
})

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});
const useStyles = makeStyles((theme) => ({
    image: {
        [theme.breakpoints.up('md')]: {
            width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image1: {
        [theme.breakpoints.up('md')]: {
            width: '670px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image2: {
        [theme.breakpoints.up('md')]: {
            width: '685px !important', height: '390px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    image3: {
        [theme.breakpoints.up('md')]: {
            width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '45% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
        }
    },
    imageContainer1: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '55% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    imageContainer2: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '2% !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
            alignItems: 'center'


        }

    },
    imageContainer3: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '60% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',

        }

    },
    gridRoot: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot1: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot2: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridRoot3: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },
    gridContent: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            color: '#6D5DF6',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '350px !important', // Set height
            textAlign: 'center',
            justifyContent: 'center', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            maxWidth: '100%',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#0080e5',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '280px !important', // Set height
            textAlign: 'center',
            justifyContent: 'flex-start', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 0 18px -4px rgba(177,177,177,.7)',
            width: '100% !important'
            // '&:hover': {
            //     boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            // }
        }
    },
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80% !important',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '25.333% !important',
        }
    },

}));
const CRM = () => {
    const classes = useStyles();
    const { ref: containerRef, inView: containerInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef1, inView: containerInView1 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef2, inView: containerInView2 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRef3, inView: containerInView3 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRefHero, inView: containerHero } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ margin: '2% auto', maxWidth: '100% !important', padding: '0 !important' }}>
                <Grid size='large' container spacing={4} alignItems="center" sx={{ maxWidth: '90%', margin: '0 auto 40px', display: { xs: 'none', md: 'flex' }, alignItems: 'flex-start', minHeight: '80vh' }}>

                    <Grid item xs={12} md={6} sx={{ maxWidth: '45% !important', paddingTop: '55px !important', paddingLeft: '0 !important', minHeight: '100%' }}>

                        <MainTitle sx={{ textAlign: 'left', wordBreak: 'break-word', fontSize: '6vh' }}>
                            Boost  your Sales with  World's Best  <Typography component="span" sx={{ fontSize: { xs: '24px', md: '6vh' }, color: '#0080e5', fontWeight: 'bold' }}>CRM </Typography>system

                        </MainTitle>
                        <SubTitle>
                            Enhance your work automation with the world’s fastest Sales CRM software.            </SubTitle>

                            <Box
                                    textAlign="center"
                                    mb={4}
                                    sx={{
                                        margin: { xs: '10px 0', md: '32px 0' },
                                        width: { xs: '100%', sm: '80%', md: '70%' },
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: 'space-around',
                                    }}
                                    >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        lineHeight: '24px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#0080ef',
                                        border: '1px solid #d6d6d8',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': {
                                            backgroundColor: '#0070d4',
                                            transform:'scale(0.9)'
                                        },
                                        }}
                                    >
                                        Explore Demo Login
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href="/request-demo"
                                        sx={{
                                        margin: { xs: '10px auto', sm: '0 8px' },
                                        lineHeight: 1.5,
                                        border: '2px solid #0080e5',
                                        textTransform: 'none',
                                        padding: '1rem 1rem',
                                        width: { xs: '100%', sm: '220px' },
                                        borderRadius: '4rem',
                                        fontSize: '12px',
                                        '&:hover': { background: '#edeff5',
                                            transform:'scale(0.9)'
                                         },
                                        }}
                                    >
                                        Book a Demo
                                    </Button>
                                    </Box>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{ borderRadius: '12px', maxWidth: '45% !important', aspectRatio: '1.84', paddingTop: '0 !important', paddingLeft: '14px !important', textAlign: 'right !important', Height: '700px !important' }}>
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6465d3a7061c678b6a4a9eaa_CRM%20Home.gif" 
                            style={{ width: '500px', height: '433px' }} 
                            alt="image1"
                        />
                    </Grid>
                </Grid>
                <Grid
                container
                spacing={4}
                alignItems="center"
                sx={{
                    minHeight: '60vh',
                    maxWidth: { xs: '100%', sm: '90%', md: '400px' },
                    margin: '0 auto 40px',
                    display: { xs: 'flex', md: 'none' },
                    textAlign: 'center',
                }}
                >
                <Grid 
                    item xs={12} 
                    md={6} sx={{ textAlign: 'center',
                        marginLeft:{
                            xs : '-10px'
                        }
                    }}>
                    <MainTitle
                    sx={{
                        fontSize: { xs: '24px', sm: '28px', md: '32px' },
                        textAlign: 'center',
                        wordBreak: 'break-word',
                    }}
                    >
                    Boost your Sales with World's Best{' '}
                    <Typography
                        component="span"
                        sx={{
                        fontSize: { xs: '28px', sm: '32px', md: '6vh' },
                        color: '#0080e5',
                        fontWeight: 'bold',
                        }}
                    >
                        CRM{' '}
                    </Typography>
                    system
                    </MainTitle>
                    <SubTitle>
                    Enhance your work automation with the world’s fastest Sales CRM software.
                    </SubTitle>
                    <Box
                    textAlign="center"
                    mb={4}
                    sx={{
                        margin: { xs: '10px 0', md: '32px 0' },
                        width: { xs: '100%', sm: '80%', md: '70%' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-around',
                    }}
                    >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        backgroundColor: '#0080ef',
                        border: '1px solid #d6d6d8',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': {
                            backgroundColor: '#0070d4',
                        },
                        }}
                    >
                        Explore Demo Login
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        href="/request-demo"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: 1.5,
                        border: '2px solid #0080e5',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': { background: '#edeff5' },
                        }}
                    >
                        Book a Demo
                    </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} 
                    sx={{ maxWidth: '100%', 
                        width: '100%', 
                        paddingLeft: 0, 
                        overflow: 'hidden' }}>
                    <Box
                    sx={{
                        width: '100%',
                        height: { xs: 'auto', sm: '370px' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                    <img
                        src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6465d3a7061c678b6a4a9eaa_CRM%20Home.gif"
                        style={{
                        width: '100%',
                        maxWidth: { xs: '100%', sm: '370px' },
                        height: 'auto',
                        }}
                        alt="CRM GIF"
                    />
                    </Box>
                </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="flex-start" sx={{ 
                        minHeight:{xs:'120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}
                    ref={containerRef}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Powerful Lead Management
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif' }}>
                            OneHash CRM is a cloud-based solution
                            <br />
                            designed to help businesses,
                            <br />
                            manage sales opportunities and enhance work automation
                        </Typography>
                        <ExploreButton className={containerInView ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#0080e5' }}>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    
                    <Grid item sx = {{
                        marginLeft:{
                            xs : '-40px',
                        }
                    }}>
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112b140d249cd65cdf7673a_Salesforecasting.svg" style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '550px', 
                            display: 'block',
                            margin: '0 auto',
                            
                            }}
                            alt = "image1"    
                        />
                    </Grid>
                </Grid>

                <Grid container
                    spacing={1}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}
                >
                    {content.map((content, index) => (
                       <Grid 
                       item 
                       xs={12} 
                       md={4}   
                       key={index} 
                       sx={{
                         display: 'flex',
                         justifyContent: 'center',  
                         maxWidth: { md: '25.333% !important' },
                       }}
                     >
                            <Box sx={{
                                backgroundColor: '#fff',  
                                padding: '16px',         
                                borderRadius: '8px',     
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                display: 'flex',
                                flexDirection: 'column', 
                                alignItems: 'center',   
                                width: { xs: '80%', md: '100%' },  
                            }}>
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >

                <Grid ref={containerRef1} 
                    container spacing={4} 
                    alignItems="flex-start" 
                    sx={{ minHeight: {xs:'120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f9a400', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item 
                    
                    sx = {{
                        marginLeft:{
                            xs : '-40px',
                            md : '-20px'
                        }
                    }}>
                        <img 
                        src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6112b0a8ef2782230a47d6fd_HRM%20Features.svg"             style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '600px', 
                            display: 'block',
                            margin: '0 auto',
                            paddingLeft:'10px'
                        
                          }}
                        alt = "crm4"  
                        />
                    </Grid>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Accurate CRM Analytics
                        </MainTypo>
                        <Typography 
                            sx={{ fontFamily: '"Poppins", sans-serif', 
                                marginTop:{
                                    xs : '20px',
                                    md:'0px'
                                }

                            }}>
                            Evaluate organization's customer data for streamlining sales processes and evaluating opportunity and lead generating funnels. Measure the effectiveness of ongoing campaigns, profitability of current deals, and predict the future opportunity-generating models.
                        </Typography>
                        <ExploreButton className={containerInView1 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f9a400' }}>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>

                </Grid>
                <Grid container
                spacing={2}
                className="container-grid"
                sx={{
                marginTop: { xs: '-40px', md: '-120px' },
                justifyContent: 'center', 
                textAlign: 'center',
                alignItems: 'stretch',
                }}>
                    {content1.map((content, index) => (
                         <Grid 
                         item 
                         xs={12} 
                         md={4}   
                         key={index} 
                         sx={{
                           display: 'flex',
                           justifyContent: 'center',  
                           maxWidth: { md: '25.333% !important' },
                         }}
                       >
                            <Box sx={{
                                backgroundColor: '#fff',  
                                padding: '16px',         
                                borderRadius: '8px',     
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                display: 'flex',
                                flexDirection: 'column', 
                                alignItems: 'center',   
                                width: { xs: '80%', md: '100%' }, 
                               
                            }}>
                                <img src={content.image} 
                                    style={{ textAlign: 'center', margin: '0 auto',
                                        maxwidth : {xs : '120px',md:'320px'}
                                     }} 
                                    alt = "content2" 
                                />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#f9a400', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef2} container 
                spacing={4} alignItems="flex-start" 
                sx={{ 
                    minHeight:{xs : '120vh',md:'110vh'}, 
                    maxWidth: '100% !important', 
                    margin: '60px 0  auto', 
                    display: { xs: 'flex', md: 'flex' }, 
                    justifyContent: { xs: 'center', md: 'space-around' }, 
                    maxHeight: '100vh', 
                    background: '#008c44', 
                    color: '#F1EEFF', 
                    paddingLeft:'30px',
                    paddingTop:'30px !important'
                    }}>
                    <Grid item sx={{ maxHeight: '100%', 
                            paddingTop: { xs: '0 !important', md: '5% !important' }, 
                            textAlign: { xs: 'left', md: 'left' } }} 
                            className={classes.gridRoot2}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Interactive Workflow Automation
                        </MainTypo>
                        <Typography 
                        sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '18px', color: '#fff'                             ,marginTop:{
                                xs : '20px',
                                md : '0px'
                            }
                        }}>
                            Intelligently automate your team's time-consuming chores so they can focus on more essential responsibilities with efficient automation tools. Change client status, generate immediate emails for query resolution, and stay connected with customers with smooth drip campaigns through newsletters, and automated posts for Twitter and LinkedIn.
                        </Typography>
                        <ExploreButton className={containerInView2 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#008c44' }} >
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid 
                    sx = {{
                        marginLeft:{
                            xs : '50px',
                        }
                    }}>
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/6111508b920352462e4b75a0_Interactive%20Workflow%20Automation-1.svg"             style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '600px',  
                            display: 'block',
                            margin: '0 auto',
                            }}
                        alt = "crm5"    
                        />
                    </Grid>
                </Grid>

                <Grid 
                    container
                    spacing={2}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}          
                    
                >
                    {content2.map((content, index) => (
                        <Grid 
                        item 
                        xs={12} 
                        md={4}   
                        key={index} 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',  
                          maxWidth: { md: '25.333% !important' },
                        }}
                      >
                            <Box sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}                    
                            >
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "content4" />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#008c44', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >


                <Grid ref={containerRef3} container spacing={4} alignItems="flex-start" sx={{ minHeight: '120vh', maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#f1352c', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid >
                        <img src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/61125abf0590f5bb1b7cfc6d_Seamless%20System%20Configuration.svg"             style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '600px', 
                            display: 'block',
                            margin: '0 auto',
                            }}
                        alt = "CRM2"
                        />
                    </Grid>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot1}>
                        <MainTypo sx={{ fontSize: { xs: '5vh', md: '6vh' } }}>
                            Seamless System Configuration
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif',marginTop:{xs:'20px',md:"0px"} }}>
                            Easily configure unique views, connect information, test modifications into the system, and even select the language and currency as per the needs with custom fields, buttons, and layouts. Integrate information from multiple sources and configure your profile the way you desire.                        </Typography>
                        <ExploreButton className={containerInView3 ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#f1352c' 
                        ,marginLeft:{xs:'-1px',md:'0px'}
                    }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>

                </Grid>
                <Grid  container
                    spacing={2}
                    className="container-grid"
                    sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                    }}>
                    {content3.map((content, index) => (
                        <Grid item 
                        xs={12} 
                        md={4}   
                        key={index} 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',  
                          maxWidth: { md: '25.333% !important' },
                        }} >
                            <Box  sx={{
                                    backgroundColor: '#fff',  
                                    padding: '16px',         
                                    borderRadius: '8px',     
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    alignItems: 'center',   
                                    width: { xs: '80%', md: '100%' },  
                                }}>
                                <img src={content.image} style={{ textAlign: 'center', margin: '0 auto' }} alt = "content1"  />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6" sx={{ background: 'none', color: '#f1352c', fontFamily: '"Poppins", sans-serif !important' }}>{content.title}</Typography>
                                    <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins", sans-serif !important' }}>{content.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid >
            </Container >
            <Container
          sx={{
            marginTop: '0 !important',
            minHeight: '50vh', 
            height: 'auto',
            paddingTop: { xs: '0px', md: '10px', lg: '10px' },
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: { xs: '0 auto', md: '0 auto !important' },
            width:'1240px !important',
            maxWidth:'100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={containerRefHero}
        >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '55vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>


            <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-60px',  
                    lg: '-80px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
            </Container>
        </ThemeProvider >
    );
};
export default CRM;