import React from "react";
import { Typography, Container, Button, Box, Grid, Icon } from "@mui/material";
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Footer from "./Footerhcm";
import CreateIcon from '@mui/icons-material/Create';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BarChartIcon from '@mui/icons-material/BarChart';
import as1 from '../images/asset-sub-1.png';
import amh from '../images/asset-mngt-main.jpg';
import { useInView } from 'react-intersection-observer';

const content = [
    {
        title: "Asset Creation and Classification",
        description: "Each asset is assigned a unique ID, facilitating easy tracking and management. Categories can be tailored to suit different asset types, such as machinery, office equipment, or IT hardware. This helps in organizing assets and enables quick access to specific information about each asset.",
        image: <CreateIcon />
    },
    {
        title: "Acquisition and Depreciation",
        description: "When acquiring new assets, details like supplier information, purchase date, and cost are recorded. The system supports multiple depreciation methods, including straight-line and diminishing value, to calculate the asset's depreciation over time.",
        image: <ShoppingCartIcon />
    },
    {
        title: "Asset Movement and Tracking",
        description: "This feature tracks the physical location and movement of assets within the organization. Assets can be assigned to different employees or departments, and any relocation is recorded. This tracking ensures accountability and helps in quickly locating assets when needed.",
        image: <TrackChangesIcon />
    },
    {
        title: "Maintenance Management",
        description: "Schedule regular maintenance activities to ensure assets are in good working condition. Record maintenance history and associated costs to monitor the asset's upkeep over time. This proactive approach helps in extending the asset's lifespan, preventing unexpected breakdowns.",
        image: <BuildCircleIcon />
    },
    {
        title: "Disposal and Write-off",
        description: "Manage the process of disposing of assets that are no longer functional or needed. This includes recording the reason for disposal, the method used (such as sale, donation, or scrapping), and ensuring proper accounting treatment for write-offs.",
        image: <AssignmentTurnedInIcon />
    },
    {
        title: "Reporting and Analytics",
        description: "Generate detailed reports on various aspects of asset management, including asset value, depreciation, maintenance history, and utilization rates. These reports provide valuable insights for decision-making, helping organizations optimize asset use, plan for future acquisitions, and improve financial management.",
        image: <BarChartIcon />
    },
]



const MainTitle = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '1rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#474d66'
});

const SubTitle = styled(Typography)({
    fontSize: '1.25rem',
    marginBottom: '2rem',
    fontFamily: '"Poppins", sans-serif !important',
    color: '#8f95b2',


});

const CallToAction = styled(Button)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    borderRadius: '100px',
    fontFamily: '"Poppins", sans-serif !important',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#585bd5',
        border: '2px solid #585bd5',
        boxShadow: 'none',
        fill: '#585bd5',
    },
});

const HeroContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gridRowGap: '30px',
    width: '80%', 
    maxWidth: '1000px',
    margin: '0 auto',
    maxHeight:'300px'
  }));

const HeroLogo = styled(Box)({
    display: 'inline-block',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '25px',
    marginBottom: '20px',
    marginTop: '20px',

});
const HeroButton = styled(Button)({
    height: '60px',
    width: '160px',
    borderRadius: '100px !important',
    /* padding: 8px 13px; */
    transition: '0.2s',
    textTransform: 'none!important',
    boxShadow: 'none'

});

const ExploreButton = styled(Button)({
    lineHeight: 1.5, fontWeight: 500, backgroundColor: '#fff', border: '1px solid #d6d6d8', textTransform: 'none !important', padding: '1rem 1rem', width: '220px', borderRadius: '4rem', marginTop: '20px !important', fontFamily: '"Poppins", sans-serif !important', fontSize: '16px',
    '&:hover': {
        background: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }

})

const MainTypo = styled(Typography)({
    color: '#fff',
    fontWeight: 500,
    lineHeight: 1.3,
    fontFamily: 'poppins, sans-serif !important '
})

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});


const useStyles = makeStyles((theme) => ({
    image: {
        [theme.breakpoints.up('md')]: {
            width: '550px !important', height: '450px !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important', objectFit: 'cover', borderRadius: '20px', transition: 'width 0.3s, height 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(105, 96, 236, 0.3), 0 6px 20px rgba(105, 96, 236, 0.3)',
            },
        },
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '12px',
            maxWidth: '100% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '4px !important',
            textAlign: 'left !important',
            marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px',
            maxWidth: '45% !important',
            aspectRatio: '1.84',
            paddingTop: '0 !important',
            paddingLeft: '14px !important',
            textAlign: 'center !important',
        }
    },

    gridRoot: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50% !important', maxHeight: '100%  !important', display: 'flex', flexDirection: 'column !important', justifyContent: 'flex-start'
        }
    },

    gridContent: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            color: '#6D5DF6',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '350px !important', // Set height
            textAlign: 'center',
            justifyContent: 'center', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            maxWidth: '100%',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            }
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#0080e5',
            flexDirection: 'column !important', // Ensure vertical alignment
            border: '1px solid #FAF9FC', // Border color as background color
            borderRadius: '20px',
            height: '280px !important', // Set height
            textAlign: 'center',
            justifyContent: 'flex-start', // Distribute space around content
            padding: '16px', // Adjusted padding
            backgroundColor: '#FFFFFF', // Set background color
            boxShadow: '0 0 18px -4px rgba(177,177,177,.7)',
            width: '100% !important'
            // '&:hover': {
            //     boxShadow: '0 4px 8px rgba(0, 128, 229, 0.3), 0 6px 20px rgba(0, 128, 229, 0.3)',

            // }
        }
    },
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80% !important',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '25.333% !important',
        }
    },

}));



const AssetManagement = () => {
    const classes = useStyles();
    const { ref: containerRef, inView: containerInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: containerRefHero, inView: containerHero } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    return (
        <ThemeProvider theme={theme}>
            <Container 
              sx={{ margin: '2% auto', 
              maxWidth: '100% !important', 
              padding: '0 !important' }}>
              <Grid
                container
                spacing={4}
                alignItems="center"
                sx={{
                  maxWidth: '90%',
                  margin: '0 auto 40px',
                  minHeight: '80vh',
                  display: { xs: 'flex', md: 'flex' },
                  flexDirection: { xs: 'column', md: 'row' }, // Column layout for small screens, row for medium and larger
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    maxWidth: { xs: '100%', md: '45%' }, // Full width on small screens, 45% on medium and large
                    paddingTop: { xs: '20px', md: '55px' }, // Adjust padding for different screen sizes
                    paddingLeft: '0 !important',
                    minHeight: 'auto',
                    textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                  }}
                >
                  <MainTitle
                    sx={{
                      wordBreak: 'break-word',
                      fontSize: { xs: '24px', md: '6vh' }, // Responsive font size
                    }}
                  >
                    Only Scheduling tool you need - FusionERP{' '}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { xs: '24px', md: '6vh' },
                        color: '#0080e5',
                        fontWeight: 'bold',
                      }}
                    >
                      Asset Management
                    </Typography>
                  </MainTitle>
                  <SubTitle
                    sx={{
                      fontSize: { xs: '14px', md: '16px' }, // Adjust subtitle size for responsiveness
                    }}
                  >
                    Helps businesses efficiently manage and maintain their assets, ensuring optimal utilization and minimizing downtime.
                  </SubTitle>
                  <Box
                    textAlign="center"
                    mb={4}
                    sx={{
                        margin: { xs: '10px 0', md: '32px 0' },
                        width: { xs: '100%', sm: '80%', md: '70%' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-around',
                    }}
                    >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        backgroundColor: '#0080ef',
                        border: '1px solid #d6d6d8',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': {
                            backgroundColor: '#0070d4',
                             transform:'scale(0.9)'
                        },
                        }}
                    >
                        Explore Demo Login
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        href="/request-demo"
                        sx={{
                        margin: { xs: '10px auto', sm: '0 8px' },
                        lineHeight: 1.5,
                        border: '2px solid #0080e5',
                        textTransform: 'none',
                        padding: '1rem 1rem',
                        width: { xs: '100%', sm: '220px' },
                        borderRadius: '4rem',
                        fontSize: '12px',
                        '&:hover': { background: '#edeff5', transform:'scale(0.9)' },
                        }}
                    >
                        Book a Demo
                    </Button>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                      width: '100%',
                      height: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft:{
                        xs:'-22px',
                        md:'0px'
                      }
                    }}
                >
          <Box
            component="img"
            src={amh}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
                </Grid>
              </Grid>
               <Grid ref={containerRef} container spacing={4} alignItems="flex-start" sx={{ 
                  minHeight:{xs : '120vh',md:'110vh'}, maxWidth: '100% !important', margin: '60px 0  auto', display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'space-around' }, maxHeight: '100vh', background: '#0080e5', color: '#F1EEFF', padding: '30px 0 !important', }}>
                    <Grid item sx={{ maxHeight: '100%', paddingTop: { xs: '0 !important', md: '10% !important' }, textAlign: { xs: 'left', md: 'left' } }} className={classes.gridRoot}>
                        <MainTypo sx={{ fontSize: { xs: '4vh', md: '6vh' } }}>
                            Maximize Your Asset <br />Utilization
                        </MainTypo>
                        <Typography sx={{ fontFamily: '"Poppins", sans-serif',

                          marginTop:{
                            xs:'20px',
                            md:'0px'
                          }
                         }}>
                            Fusion Asset helps businesses efficiently manage and maintain their assets, ensuring optimal utilization and minimizing downtime.

                        </Typography>
                        <ExploreButton 
                          className={containerInView ? 'expand-animation' : ''} sx={{ margin: { xs: '10px auto', md: '0' }, color: '#0080e5',
                          marginLeft:{
                            xs:'-2px',
                            md:'0px'
                          }
                          }} href='/request-demo'>
                            Explore Demo Login
                        </ExploreButton>
                    </Grid>
                    <Grid
          sx={{
            marginTop: '40px',
            marginLeft: { xs: '0px', md: '-40px' }, 
          }}
        >
          <Box
            component="img"
            src={as1}
            alt="Recruitment"
            sx={{
              height: 'auto',
              maxWidth: { xs: '350px', sm: '350px', md: '530px', lg: '530px' },
              display: 'block',
              margin: '0 auto',
              borderRadius: '20px',
            }}
          />
        </Grid>
                </Grid>
                <Grid container spacing={1} className="container-grid"             sx={{
                    marginTop: { xs: '-40px', md: '-120px' },
                    justifyContent: 'center', 
                    textAlign: 'center',
                    alignItems: 'stretch',
                  }}
                >
                  {content.map((content, index) => (
                    <Grid 
                    item 
                    xs={12} 
                    md={4}   
                    key={index} 
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',  
                      maxWidth: { md: '25.333% !important' },
                    }}
                  >
                        <Box sx={{
                            backgroundColor: '#fff',  
                            padding: '16px',         
                            borderRadius: '8px',     
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                            display: 'flex',
                            flexDirection: 'column', 
                            alignItems: 'center',   
                            width: { xs: '80%', md: '100%' },  
                          }}
                          >
                            <Icon style={{ textAlign: 'center', margin: '0 auto' }}> {content.image}</Icon>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="h6" sx={{ background: 'none', color: '#0080e5', fontFamily: '"Poppins",sans-serif !important' }}>{content.title}</Typography>
                                <Typography variant="body2" sx={{ background: 'none', color: 'black', fontFamily: '"Poppins",sans-serif !important' }}>{content.description}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                  ))}
                </Grid >
            </Container >
            <Container
          sx={{
            marginTop: '0 !important',
            minHeight: '50vh', 
            height: 'auto',
            paddingTop: { xs: '0px', md: '10px', lg: '10px' },
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: { xs: '0 auto', md: '0 auto !important' },
            width:'1240px !important',
            maxWidth:'100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={containerRefHero}
        >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '55vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>
        <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-60px',  
                    lg: '-80px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default AssetManagement;