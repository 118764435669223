import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { CssBaseline, Drawer, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { useNavigate } from 'react-router-dom';
import ciphercode from '../images/logo-png.png';
import './Header.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

const pages = ['Product', 'Pricing', 'Contact Us'];
const signpages = ['SignUp', 'SignIn'];
const settings = ['Profile', 'Logout'];

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
        },
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
  },
});

function Headerhcm() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [hoveredPage, setHoveredPage] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [mobileSubmenuOpen, setMobileSubmenuOpen] = useState(null);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav((prev) => (prev ? null : event.currentTarget));
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setMobileSubmenuOpen(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMouseEnter = (page) => {
    setHoveredPage(page);
    if (page !== 'Pricing' && page !== 'SignUp' && page !== 'SignIn') {
      setSubmenuOpen(page);
    }
  };

  const handleMouseLeave = () => {
    setHoveredPage(null);
    setSubmenuOpen(null);
  };

  const handleSubmenuMouseEnter = () => {
    setSubmenuOpen(hoveredPage);
  };

  const handleSubmenuMouseLeave = () => {
    setSubmenuOpen(null);
  };

  const handleMobileSubmenuToggle = (page) => {
    setMobileSubmenuOpen((prev) => (prev === page ? null : page));
  };

  function renderPopupContent(page) {
    let itemsToRender = null;

    const products = ['HRMS', 'CRM', 'ERP', 'Assets'];
    const productIcons = {
      'HRMS': {
        icon: <AssignmentIcon />,
        description: 'Human Resource Management System'
      },
      'CRM': {
        icon: <FactCheckOutlinedIcon />,
        description: 'Customer Relationship Management'
      },
      'ERP': {
        icon: <AssignmentIcon />,
        description: 'Enterprise Resource Planning'
      },
      'Assets': {
        icon: <AssignmentIcon />,
        description: 'Asset Management'
      }
    };

    switch (page) {
      case 'Product': {
        const productColumns = 2;
        const productRows = Math.ceil(products.length / productColumns);
        const productItemsPerColumn = Math.ceil(products.length / productRows);

        itemsToRender = (
          <Box
            sx={{
              gap: 2,
              width: { xs: '100%', md: '350px' },
              display: { xs: 'block', md: 'flex' },
              position: 'relative',
              transition: '0.3s all ease-in-out',
              borderRadius: '6px',
              boxShadow: { md: '0 3px 5px 1px rgba(207,206,206,.81)' },
              marginTop: '-12px',
              zIndex: 10,
              backgroundColor: '#fff',
              padding: { xs: '0px', md: '0px 10px' },
              overflow: 'visible',
            }}
          >
            {[...Array(productColumns)].map((_, columnIndex) => (
              <Box
                key={columnIndex}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  maxWidth: '100%',
                  overflow: 'visible',
                  width: { xs: '100%', md: '50%' },
                  position: 'relative',
                }}
              >
                {products
                  .slice(columnIndex * productItemsPerColumn, (columnIndex + 1) * productItemsPerColumn)
                  .map((product) => {
                    const { icon, description } = productIcons[product];
                    return (
                      <Button
                        key={product}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '-10px',
                          color: 'black',
                          cursor: 'pointer',
                          height: 'auto',
                          width: '100%',
                          position: 'relative',
                          
                        }}
                        onClick={() => navigate(`/${product.toLowerCase()}`)}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 1,
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            padding: '2px',
                          }}
                        >
                          <Avatar
                            sx={{
                              bgcolor: '#F1EEFF',
                              color: '#6960ec',
                              width: 26,
                              height: 26,
                              borderRadius: '5px',
                              display: 'none',
                            }}
                          >
                            {icon}
                          </Avatar>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 500, color: '#474d66', fontSize: '16px' }}
                          >
                            {product}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              textAlign: 'left',
                              width: '100%',
                              whiteSpace: 'wrap',
                              color: '#8f95b2',
                              textTransform: 'none',
                              fontSize: '12px',
                            }}
                          >
                            {description}
                          </Typography>
                        </Box>
                      </Button>
                    );
                  })}
              </Box>
            ))}
          </Box>
        );
        break;
      }
      
      // case 'Company': {
      //   itemsToRender = (
      //     <Box
      //       sx={{
      //         gap: 4,
      //         width: '100%',
      //         paddingLeft: '5px',
      //         flexDirection: 'column',
      //         padding: '10px',
      //         display: 'flex',
      //         position: 'relative', 
      //         transition: '0.3s all ease-in-out',
      //         borderRadius: '6px',
      //         boxShadow: { md: '0 3px 5px 1px rgba(207,206,206,.81)' },
      //         marginTop: '-12px',
      //         backgroundColor: 'white',
      //         '&:hover .dropdown-list': { 
      //           display: 'flex',
      //         },
      //       }}
      //     >
      //       {['About Us', 'Contact'].map((company) => (
      //         <Box
      //           key={company}
      //           sx={{
      //             display: 'flex',
      //             alignItems: 'center',
      //             gap: 1,
      //             color: 'black',
      //             width: '100%',
      //             position: 'relative', 
      //             marginLeft:{xs : '-10px',
      //               md:'5px'
      //             },
      //             '&:hover .dropdown-content': { 
      //               display: 'flex',
                   
      //             },
      //           }}
      //           onClick={() => {
      //             if (company === 'About Us') {
      //               navigate('/aboutus');
      //             } else if (company === 'Contact') {
      //               navigate('/contact');
      //             }
      //           }}
      //           style={{ cursor: 'pointer' }}
      //         >
      //           <Avatar sx={{ bgcolor: '#F1EEFF', color: '#6960ec', width: 26, height: 26, borderRadius: '5px', display: 'none' }}>
      //             {company === 'About Us'}
      //           </Avatar>
      //           <Typography variant="body2" sx={{ display: 'flex', width: '100px !important' }}>
      //             {company}
      //           </Typography>
      //         </Box>
      //       ))}
      //     </Box>
      //   );
      //   break;
      // }
      
      default:
        itemsToRender = null;
    }

    return itemsToRender;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ padding: '2', backgroundColor: 'white', boxShadow: '0 1px 11px 3px #ececec4d', height: '60px', zIndex: 1200 }}>
        <Container maxWidth="xl" sx={{ height: '60px', paddingLeft: { xs: '0', md: '70px !important' } }}>
          <Toolbar disableGutters sx={{ justifyContent: 'space-between', padding: '10px 0px', height: '64px', margin: '0 2%' }}>
            <Box size='large' component="img" sx={{ display: { xs: 'none', md: 'flex' }, width: '145px', marginTop: '-5px', marginLeft: '-30px' }} src={ciphercode} alt="Logo" onClick={handleLogoClick} />
            <Box 
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, 
            justifyContent: 'center', alignItems: 'left', gap: 2, 
            margin: '0 0 0 2%', fontSize: '16px' }} className="btn-svg-hover">
              {pages.map((page) => (
                <Box
                  key={page}
                  sx={{ position: 'relative', display: 'inline-block' }}
                  onMouseEnter={() => handleMouseEnter(page)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Button
                    onClick = {() =>{
                      if (page === 'Contact Us') {
                        navigate('/contact');
                      }else if( page === "Product"){
                        navigate("/")
                      }                     
                      else {
                        navigate(`/${page.toLowerCase()}`);
                      }
                    }}
                    sx={{
                      my: 2,
                      color: 'black',
                      display: 'block',
                      padding: '8px 15px',
                      fontSize: '16px',
                      textTransform: 'none',
                      fontWeight: 400,
                      fontFamily: 'Poppins, sans-serif !important',
                      
                    }}
                  >
                    {page}
                   
                  </Button>

                  {submenuOpen === page && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        bgcolor: 'background.paper',
                        boxShadow: 0,
                        zIndex: 1,
                        border: '1px solid #fff',
                        borderRadius: '10px',
                        fontSize: '16px',
                        textTransform: 'none',
                        fontWeight: 400,

                        fontFamily: 'Poppins, sans-serif !important',
                      }}
                      onMouseEnter={handleSubmenuMouseEnter}
                      onMouseLeave={handleSubmenuMouseLeave}
                    >
                      {renderPopupContent(page)}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0,marginRight:'15px', display: { xs: 'none', md: 'flex' } }}>
              {signpages.map((page) => (
                <Box
                  key={page}
                  sx={{ position: 'relative', display: 'inline-block' ,
                    
                  }}
                  onMouseEnter={() => handleMouseEnter(page)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Button
                    onClick={() => navigate(`/${page.toLowerCase()}`)}
                    sx={{
                      my: 2,
                      color: 'black',
                      display: 'block',
                      padding: '8px 15px',
                      fontSize: '16px',
                      textTransform: 'none',
                      fontFamily: 'Poppins,sans-serif !important',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    {page}
                  </Button>
                </Box>
              ))}
              <Button onClick={() => navigate(`/request-demo`)} sx={{
                width: '150px', borderRadius: '40px', color: '#fff', backgroundColor: '#1976d2', transition: '0.2s', border: '2px solid transparent', height: '40px', marginTop: '5%', fontFamily: 'Poppins, sans-serif !important', fontWeight: 400,

              }} className='btn-rqst-demo'>Request a Demo</Button>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
            </Box>
            <Box size='small' component="img" sx={{ display: { xs: 'flex', md: 'none' }, width: '150px', marginTop: '-10px' }} src={ciphercode} alt="Logo"
              onClose={handleCloseNavMenu}

              onClick={ ()=> {
                handleLogoClick ()
                handleCloseNavMenu() }} 
            />
            <Box 
              sx={{ 
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' }, 
                justifyContent: 'flex-end' 
              }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
                edge='end'
              >
                {Boolean(anchorElNav) ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <Drawer
                  anchor="top"
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    position: 'fixed',
                    top: '60px',
                    width: '100%',
                    zIndex: 10,
                  }}
                >
              <Box
                onClick={handleCloseNavMenu}
                onKeyDown={handleCloseNavMenu}
                sx={{ paddingTop: '54px' }}
              >
                {pages.map((page) => (
                  <Box key={page}>
                    {['Pricing','Contact Us'].includes(page) ? (
                      <MenuItem onClick={() => navigate(page  === 'Contact Us' ? '/contact' : '/pricing')
                      }>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={() => handleMobileSubmenuToggle(page)}>
                        <Typography textAlign="center" sx={{ display: 'inline' }}>{page}</Typography>
                        {page !== 'SignUp' && page !== 'SignIn' && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMobileSubmenuToggle(page);
                            }}
                                          
                          >
                              {mobileSubmenuOpen === page ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </IconButton>
                          )}
                        </MenuItem>
                      )}
                      {mobileSubmenuOpen === page && page !== 'Pricing' && page !== 'SignUp' && page !== 'SignIn' && (
                        <MenuItem>
                          <Box 
                            sx={{ display: 'flex', flexDirection: 'column', gap: 0, padding: 0 
                            
                            }}>
                            {renderPopupContent(page)}
                          </Box>
                        </MenuItem>
                      )}
                    </Box>
                  ))}
                    {signpages.map((page) => (
                          <Box
                            
                            key={page}
                            sx={{
                              position: 'relative',
                              display: 'flex',
                              flexDirection: 'row',
                             
                              textTransform: 'none',
                              fontWeight: 400,
                              fontFamily: 'Poppins, sans-serif !important',
                              padding: '4px 0',
                              marginLeft:'6px',

                        }}
                        onMouseEnter={() => handleMouseEnter(page)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Button
                          onClick={() => navigate(`/${page.toLowerCase()}`)}
                          sx={{
                            color: 'black',
                            display: 'block',
                            padding: '6px 12px',
                            
                            textTransform: 'none',
                            fontWeight: 400,
                            fontFamily: 'Poppins, sans-serif !important',
                            
                          }}
                        >
                          {page}
                        </Button>
                        {submenuOpen === page && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              bgcolor: 'background.paper',
                              boxShadow: 0,
                              zIndex: 1,
                              border: '1px solid #caccff',
                              padding: '2px 0', 
                            }}
                            onMouseEnter={handleSubmenuMouseEnter}
                            onMouseLeave={handleSubmenuMouseLeave}
                          >
                            {renderPopupContent(page)}
                          </Box>
                        )}
                      </Box>
                    ))}
                  <Box>
                    <Button
                      onClick={() => navigate('/request-demo')}
                      sx={{
                        color: 'black',
                        display: 'block',
                        padding: '12px 12px', 
                        fontWeight: 400,
                        textTransform: 'none',
                       
                        marginLeft:'6px',
                        fontFamily: 'Poppins, sans-serif !important',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      Request free Demo
                    </Button>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider >
  );
}

export default Headerhcm;
