import React from 'react';
import { Typography, Button, Container, Grid, Box, useMediaQuery, Paper, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CardContent from '@mui/material/CardContent';
import Footer from './Footerhcm';
import './Landingpage.css';
import ferphrms from '../images/ferphrms.png';
import hrmsferp from '../images/landingpagehrms.png';
import { useInView } from 'react-intersection-observer';
import ciphercode from '../images/logo-png.png';
import classNames from 'classnames';
import erpimages from "../images/erp10.avif"
import crmimages from "../images/CRM.png"

const HeroContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#007bff',
  color: '#fff',
  borderRadius: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridRowGap: '30px',
  width: '90%',
  maxWidth: '1000px',
  margin: '0 auto',
  maxHeight:'300px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(3),
    maxHeight: 'auto', // Adjusts height for medium screens
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    gridRowGap: '20px',
    maxHeight: 'auto', // Adjusts height for small screens
  },
}));

const HeroLogo = styled(Box)({
  display: 'inline-block',
  backgroundColor: '#fff',
  borderRadius: '50%',
  padding: '25px',
  marginBottom: '20px',
  marginTop: '20px',

});
const HeroButton = styled(Button)({
  height: '60px',
  width: '160px',
  borderRadius: '100px !important',
  /* padding: 8px 13px; */
  transition: '0.2s',
  textTransform: 'none!important',
  boxShadow: 'none'

});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  rightGrid: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50% !important',
    }
  },
  leftImage: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50% !important',
    }
  },
  boxButtons: {
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '70% !important',
      justifyContent: 'flex-start',
      margin: '15px 0',
    }
  },
  signButton: {
    [theme.breakpoints.down('xs')]: {
      width: '131px',
      borderRadius: '40px !important',
      padding: '15px',
      backgroundColor: '#0080e5 !important',
      transition: 'all .45s ease-in-out',
      textTransform: 'none !important',
      fontSize: '16px !important',
      height: '50px',
      border: '1px solid transparent !important',
      fontFamily: '"Poppins", sans-serif !important',
      fontWeight: 500,
    
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
      borderRadius: '40px !important',
      backgroundColor: '#0080e5 !important',
      transition: 'all .45s ease-in-out',
      textTransform: 'none !important',
      fontSize: '16px',
      height: '50px',
      fontFamily: '"Poppins", sans-serif !important',
    }
  },
  demoButton: {
    [theme.breakpoints.down('xs')]: {
      width: '130px',
      borderRadius: '40px !important',
      padding: '15px',
      backgroundColor: '#fff !important',
      transition: 'all .45s ease-in-out',
      textTransform: 'none !important',
      fontSize: '16px',
      height: '50px',
      fontFamily: '"Poppins", sans-serif !important'
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
      borderRadius: '40px !important',
      backgroundColor: '#fff !important',
      transition: 'all .45s ease-in-out',
      textTransform: 'none !important',
      fontSize: '16px',
      height: '50px',
      fontFamily: '"Poppins", sans-serif !important',
      fontWeight: 500,
    }
  },
  mainHeading: {
    fontFamily: '"Poppins", sans-serif !important'
  },
  subHeading: {
    fontFamily: '"Poppins", sans-serif !important',
    color: '#697586',
  }
}));

const LandingPage = () => {
  const { ref: containerRef, inView: containerInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: containerRef2, inView: containerInView2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: containerRef3, inView: containerInView3 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: containerRef4, inView: containerInView4 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: containerRefHero, inView: containerHero } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const combinedClassName = classNames(classes.mainHeading, {
    'fade-in-left': containerInView,
  });
  const combinedClassName2 = classNames(classes.mainHeading, {
    'fade-in-right': containerInView2,
  });
  const combinedClassName3 = classNames(classes.mainHeading, {
    'fade-in-left': containerInView3,
  });
  const combinedClassName4 = classNames(classes.mainHeading, {
    'fade-in-right': containerInView4,
  });

  const combinedSubClassName = classNames(classes.subHeading, {
    'fade-in-left': containerInView,
  });
  const combinedSubClassName2 = classNames(classes.subHeading, {
    'fade-in-right': containerInView2,
  });
  const combinedSubClassName3 = classNames(classes.subHeading, {
    'fade-in-left': containerInView3,
  });
  const combinedSubClassName4 = classNames(classes.subHeading, {
    'fade-in-right': containerInView4,
  });



  return (
    <React.Fragment>
      <Container
        sx={{
          marginTop: '0 !important',
          backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0dabe34ce64dccbfdacf8_Hero_BG.svg)',
          backgroundPosition: { xs: '50%,65%', md: '60% 70%',lg : '100%' },
          minHeight: '100vh',
          paddingTop: { xs: '0', md: '75px' },
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          margin: { xs: '0', md: '0 auto !important' },
          maxWidth: {
            xs: '100%', 
            md: '1240px',
            lg: '2000px'
          },
          '.MuiContainer-root': {
            maxWidth: {
              xs: '100%', 
              md: '1240px',
              lg: '2000px'
            },
          }
        }} >
        <Grid container spacing={4} alignItems="center"
          sx={{
            minHeight: { xs: '20vh', md: '60vh' ,lg:'100vh'}, 
            margin: '0 auto', 
            display: { xs: 'flex', md: 'flex' ,lg:'flex'}, 
            flexDirection: { xs: 'column', md: 'row',lg:'row' }, 
            alignItems: 'flex-start',
            textAlign: 'center',
            maxWidth: '100% !important',
            padding: { xs: '0 10px', md: '0' }
          }}>

            
          <Grid container spacing={4} alignItems="center"           
            sx={{ 
              minHeight: { xs: '20vh', md: '60vh' }, 
              maxWidth: { xs: '100%', md: '50%' }, 
              margin: '0', 
              display: { xs: 'flex', md: 'flex' }, 
              alignItems: {xs : 'center',sm : 'center'},
              textAlign: {xs : 'center',sm:'center'}
              
            }}
          >
              <Box mb={4} 
                sx={{
                  margin: '0 auto',
                  lineHeight: '1.3',
                  textAlign: { 
                    xs: 'center', 
                    sm: 'center', 
                    md: 'left', 
                    lg: 'left',
                    xl : 'left' 
                  },
                  justifyContent:"space-between"
                }}
              >
                <Typography  
                  sx={{
                    fontSize: { xs: '24px', md: '42px' },
                    color: '#222',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    lineHeight: 1.3,
                    position: 'relative',
                    marginLeft: {
                      xs: 0,
                      md: '80px',
                    },
                    whiteSpace: { xs: 'normal', md: 'pre-wrap' },
                    wordBreak: { xs: 'normal', md: 'break-word' },
                    overflowWrap: { xs: 'normal', md: 'break-word' }
                  }}
                >
                  Grow Your Business With The Most Powerful All In One Software
                </Typography>


                <Typography 
                  variant="body1" 
                  color="textSecondary" 
                  gutterBottom
                  sx={{
                    lineHeight: '1.5',
                    color: '#3f434a',
                    fontSize: { xs: '14px', md: '16px' },
                    fontFamily: '"Poppins", sans-serif',
                    marginTop: { xs: '10px', sm: '10px' },
                    marginLeft:{
                      xs : 0,
                      md  : '80px',
                    }
                  }}
                >
                  Helping You Scale Faster With an agile, affordable software suite,
                  tailored for businesses of any scale.
                </Typography>
              </Box>

            <Stack 
              direction="row" 
              spacing={2} 
              mt={4}  
              sx={{
                gap: 2,
                width: '100%',
                marginLeft:{
                  xs : '0px',
                  md : '80px'
                },
                flexDirection: {
                  xs: 'center',
                  sm: 'row'
                },   
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                },
              }}
            >
              <Button 
                gap={12}
                variant="contained"  
                className='btn-start-for-free' 
                sx={{ 
                  color: '#fff', 
                  background: '#0080e5',
                  width: { xs: '100%', sm: 'auto' } 
                }}
              >
                Start for FREE
              </Button>
              <Button 
                variant="outlined"  
                className='btn-book-demo' 
                href='/request-demo' 
                sx={{ 
                  color: '#0080e5', 
                  background: '#fff', 
                  border: '2px solid #0080e5',
                  width: { xs: '100%', sm: 'auto' },
                }}
              >
                Book a Demo
              </Button>
            </Stack>


            <Box
              display="flex"
              
              justifyContent="flex-start"
              alignItems="flex-start"
              gap={3}
              sx={{ 
                paddingTop: '40px', 
                paddingLeft: '12px', 
                marginLeft:{
                  xs : '0px',
                  md : '80px',
                },
                flexDirection : {
                  xs :'column',
                  sm : 'row',
                  md : 'column',
                  lg:'column',
                  xl:'column'
                }
                
              }}
              className={containerHero ? "hero-features" : ""}
            >
              <Typography variant="body2" display="flex" alignItems="center" 
                gap = {2}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ filter: 'brightness(0) saturate(100%)' }}
                />
                Free 15 days trial
              </Typography>
              <Typography variant="body2" display="flex" 
              alignItems="center"
              gap = {2}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ filter: 'brightness(0) saturate(100%)' }}
                />
                No credit card required
              </Typography>
              <Typography variant="body2" display="flex" alignItems="center"
              gap = {2}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ filter: 'brightness(0) saturate(100%)' }}
                />
                Support 24/7
              </Typography>     
            </Box>
          </Grid>

          
          <Grid item 
              xs={12} 
              md={6} 
              sx={{ 
                textAlign: { xs: 'center', md: 'right' }, 
                padding: 
                { xs: '0 !important', 
                  md: '0 !important' 
                }, 
                marginTop : {xs : '20px'},
                flexDirection :{
                  xs : 'row',
                  sm : 'row',
                  md : 'row',
                  lg : 'column',
                  xl : 'column'
                }
              }}
            >

            <Grid container spacing={2}  >
              <Grid item 
                
                xs={12} 
                md={6} 
                sx={{ 
                  textAlign: { xs: 'center', md: 'center' },
                  padding: { xs: '0 !important', md: '0 !important' }, 
                  marginTop: { xs: '20px' },
                  marginLeft: { xs: '0', md: '210px' }
                }}
              >
                <Button sx={{ width: { xs: '90%', md: '400px' }, background: '#fff', height: { xs: 150,sm : 150, md: 110 }, textAlign: 'left', boxShadow: '6px 6px 25px rgba(140,150,157,.16)', '&:hover': { backgroundColor: '#e2f2ff', }, borderRadius: '12px', textTransform: 'none' }} href='/hrms'>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gridColumnGap: '24px', alignItems: 'center', }}>
                    <img
                      src={ferphrms}
                      alt="Cal"
                      
                      style={{ width: '70px', height: '60px'}}
                    />
                    <Box>
                      <Typography sx={{ fontFamily: '"Poppins",sans-serif !important', color: 'black',
                        fontSize:{ xs : '16px',md : '22px'} }}>
                        HRMS
                      </Typography>
                      <Typography variant="body2" color="textSecondary"  
                      
                      sx={{ fontFamily: 'Poppins,sans-serif !important', color: '#3f434a',fontSize : {xs : '13px',md : '14px'} }}>
                        The Only Software Managing your Entire Employee Lifecycle  <Typography variant="span" 
                        sx={{ color: '#0080e5' }}>Know More</Typography>
                      </Typography>

                    </Box>

                  </CardContent>
                </Button>
              </Grid>
              
              <Grid item 
              xs={12} 
              md={6} 
              sx={{ 
                textAlign: { xs: 'center', md: 'center' },
                padding: { xs: '0 !important', md: '0 !important' }, 
                marginTop: { xs: '20px' },
                marginLeft: { xs: '0', md: '210px' }
              }}
            >
                <Button sx={{ width: { xs: '90%', md: '400px' }, background: '#fff', height: { xs: 150,sm : 150, md: 110 }, textAlign: 'left', boxShadow: '6px 6px 25px rgba(140,150,157,.16)', '&:hover': { backgroundColor: '#fff2c4', }, borderRadius: '12px', textTransform: 'none' }} href='/crm'>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gridColumnGap: '24px', alignItems: 'center', }}>
                    <img
                      src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65fd8079e0080ab9eefe8a8b_crm.svg"
                      alt="Cal"
                      style={{ width: '70px', height: '60px', }}
                    />
                    <Box sx={{}}>
                      <Typography sx={{ fontFamily: '"Poppins",sans-serif !important', color: 'black', fontSize:{ xs : '16px',md : '22px'} }}>
                        CRM
                      </Typography>
                      <Typography variant="body2" color="textSecondary" 
                      sx={{ fontFamily: '"Poppins",sans-serif !important', color: '#3f434a',fontSize : {xs : '12px',md : '14px'} }}>
                        Never miss an opportunity for sales enablement again with the most efficient cloud-based CRM solution. <Typography variant="span" sx={{ color: '#f9a400' }}>Know More</Typography>
                      </Typography>

                    </Box>
                  </CardContent>
                </Button>
              </Grid>


              <Grid item 
              xs={12} 
              md={6} 
              sx={{ 
                textAlign: { xs: 'center', md: 'center' },
                padding: { xs: '0 !important', md: '0 !important' }, 
                marginTop: { xs: '20px' },
                marginLeft: { xs: '0', md: '210px' },
              }}
            >
                <Button 
                  sx={{ width: { xs: '90%', md: '400px' }, background: '#fff', height: { xs: 150,sm : 150, md: 110 }, textAlign: 'left', boxShadow: '6px 6px 25px rgba(140,150,157,.16)', '&:hover': { backgroundColor: '#c4ffe1', }, borderRadius: '12px', textTransform: 'none', }} href='/erp'>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gridColumnGap: '24px', alignItems: 'center', }}>
                    <img
                      src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/657980a73afa8347dce0b764_Red-icon.svg"
                      alt="Cal"
                      style={{ width: '70px', height: '60px', }}
                    />
                    <Box sx={{}}>
                      <Typography sx={{ fontFamily: '"Poppins",sans-serif !important', color: 'black', fontSize: '22px' }}>
                        ERP
                      </Typography>
                      <Typography variant="body2" color="textSecondary" fontSize="14px" sx={{ fontFamily: '"Poppins",sans-serif !important', color: '#3f434a' }}>
                        Get your business up and running in seconds with an efficient ERP system. <Typography variant="span" sx={{ color: '#008c44' }}>Know More</Typography>
                      </Typography>

                    </Box>
                  </CardContent>
                </Button>
              </Grid>

              <Grid item 
              xs={12} 
              md={6} 
              sx={{ 
                textAlign: { xs: 'center', md: 'center' },
                padding: { xs: '0 !important', md: '0 !important' }, 
                marginTop: { xs: '20px' },
                marginLeft: { xs: '0', md: '210px' }
              }}
            >
                <Button sx={{ width: { xs: '90%', md: '400px' }, background: '#fff', height: { xs: 150,sm : 150, md: 110 }, textAlign: 'left', boxShadow: '6px 6px 25px rgba(140,150,157,.16)', '&:hover': { backgroundColor: '#ffeae9', }, borderRadius: '12px', textTransform: 'none' }} href='/assets'>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gridColumnGap: '24px', alignItems: 'center', }}>
                    <img
                      src="https://www.manageengine.com/analytics-plus/images/features/ai-powered-analytics.svg"
                      alt="Cal"
                      style={{ width: '70px', height: '60px', }}
                    />
                    <Box sx={{}}>
                      <Typography sx={{ fontFamily: '"Poppins",sans-serif !important', color: 'black', fontSize:{xs : '16px', md : '22px'} }}>
                        Assets
                      </Typography>
                      <Typography variant="body2" color="textSecondary" 
                      sx={{ fontFamily: '"Poppins",sans-serif !important', color: '#3f434a',

                        fontSize : {xs : '12px',md : '14px'}
                       }}>
                      Optimize Asset Tracking and Boost Efficiency with Our All-in-One Management Solution.
                      <Typography variant="span" sx={{ color: '#f1352c' }}>Know More</Typography>
                      </Typography>

                    </Box>
                  </CardContent>
                </Button>
              </Grid>

            </Grid>
        
        
            </Grid>


        </Grid>


        
      </Container>


      {/* Content Working */}
      <Container
        maxWidth="false" // Set to 'false' to allow full width
        sx={{
            marginTop: '0 !important',
            minHeight: '90vh',
            paddingTop: '50px',
            width: '100%',
            '.MuiContainer-root': {
                maxWidth: 'none', 
            },
        }}
        ref={containerRef}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            width: '100%', padding: '0 10px' }}>
            <Box 
              display="flex" 
              alignItems="center" 
              justifyContent="space-between" 
              sx={{ flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', flex: '1', 
                    paddingRight: { md: '60px', lg: '80px' }, width: '100%' 
                  }}
            >
                <Typography variant="subtitle1" color="primary" ml={1}
                    sx={{ 
                        color: '#0080e5', 
                        background: '#e6f4ff', 
                        borderRadius: '100px', 
                        width: '150px', 
                        alignItems: 'center', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'space-around',
                        marginLeft: {
                            xs: '-10px',
                            md: '30px'
                        }
                    }}
                    className={containerInView ? 'fade-in-up' : ''}
                >
                    TRENDING<TrendingUpIcon />
                </Typography>
                <Typography variant="h3" component="h1" gutterBottom 
                    sx={{ 
                        fontWeight: 'bold', 
                        fontSize: { xs: '24px', md: '34px' },
                        marginLeft: { xs: '0px', md: '35px' } 
                    }}
                    className={combinedClassName}
                >
                    Optimize your everyday HR functions with - FusionERP <Typography component="span" sx={{ fontSize: { xs: '24px', 
                        md: '34px' }, color: '#0080e5', fontWeight: 'bold' }}>HRMS</Typography>
                </Typography>
                <Typography className={combinedSubClassName}
                    sx={{ marginLeft: { xs: '0px', md: '35px' } }}
                >
                    Fusion HRMS offers a complete solution for managing human resources, including payroll, attendance, and employee information, all in one place.
                </Typography>


                <Box>
                  <Grid              
                      sx={{ 
                          display: 'flex', 
                          background: 'none', 
                          flexDirection: 'column',
                          height: { xs: '110px', md: '150px' }, 
                          justifyContent: 'space-around', 
                          marginTop: { xs: '20px', md: '20px' },
                          marginLeft: { xs: '0px', md: '35px' }, 
                          width: '100%', 
                      }}
                  >
                      <Box display="flex" alignItems="center" className={containerInView ? 'fade-in-up' : ''} style={{ animationDelay: '0.3s' }}>
                          <Typography variant="body2" className='numbers-circle1'>1</Typography>
                          <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif',
                         }}>
                              Safe and efficient employee data storage
                          </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" 
                           className={containerInView ? 'fade-in-up' : ''} 
                           style={{ animationDelay: '0.4s' }}>
                          <Typography variant="body2" className='numbers-circle-2ndpoint' 
                            sx={{ marginTop: '20px'}}
                          >2
                          </Typography>
                          <Typography variant="body1" ml={1} 
                            sx={{ 
                                  marginTop:'20px', 
                                  fontSize: { xs: '14px', md: '20px' }, 
                                  fontWeight: 400, fontFamily: 'poppins, sans-serif', 
                                  whiteSpace: {xs:'nromal',md:'nowrap'}, 
                                  overflow: 'hidden', 
                                  textOverflow: 'hidden',

                                }}
                          >
                          
                          Save costs in payroll processing and benefit management</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" className={containerInView ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx={{ marginTop: '15px' }}>
                          <Typography variant="body2" className='numbers-circle1'>3</Typography>
                          <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Increase workflow automation</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" className={containerInView ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx={{ marginTop: '20px' }}>
                          <Typography variant="body2" className='numbers-circle1'>4</Typography>
                          <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Reduce errors significantly</Typography>
                      </Box>
                  </Grid>
                  <Box display="flex" 
              alignItems="flex-start" gap={2} className={classes.boxButtons} 
              sx={{ pt: { xs: 13, sm: 12, md: 3 } ,
                    ml : {xs:0,sm:0,md:5}
                  }}
              >
                <Button className={classes.signButton} 
                    sx={{ 
                        color: '#fff', 
                        background: '#0080e5 !important',
                        borderRadius: {
                            xs: '18px'
                        },
                        '&:hover':{
                          transform:'scale(0.9)'
                        }
                    }} 
                >
                    Start for FREE
                </Button>
                <Button className={classes.demoButton} href='/request-demo' sx={{ border: '2px solid #0080e5 !important',
                    color:'#0080e5 !important',
                    borderRadius: {
                        xs: '20px'
                    },
                    '&:hover':{
                      transform:'scale(0.9)'
                    }
                }} 
                >
                    Book a Demo
                </Button>
            </Box>
                </Box>
            </Box>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                flex: '1', 
                height: '500px !important', 
                marginRight: { xs: '0px', md: '20px', lg: '40px' }, // Adjust as needed for different screen sizes
                width: '100%',
            }}>
                <img src={hrmsferp}
                    style={{ width: '100%', height: 'auto', maxHeight: '600px' }}
                    className={containerInView ? 'scale-in' : ''}
                    alt="hrmsferp"
                />
            </Box>
        </Box>
      </Container>




        {/* Content End  Working */}        
        <Container 
  maxWidth={false}
  sx={{
    marginTop: '0 !important',
    minHeight: '90vh',
    paddingTop: '90px',
    width: '100%',   
    maxWidth: 'none',
    '.MuiContainer-root': {
      maxWidth: 'none'
    }
  }}
  ref={containerRef2}
>
  <Box sx={{ display: 'flex', 
    flexDirection: { xs: 'column-reverse', md: 'row' }, 
    width: '100%', padding: '0 10px' }}>
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: { xs: 'center', md: 'flex-start' }, 
        alignItems: 'center', 
        padding: '0 35px', 
        marginLeft: { xs: '20px', md: '20px' },
        marginTop: { xs: '15px', md: '0' },
        width: '100%'
      }} 
      className={classes.leftImage}
    >
      <img 
        src={crmimages}
        style={{ maxWidth: '100%', marginLeft: '-50px', maxHeight: '2280%' }}
        className={containerInView2 ? 'scale-in' : ''}
        alt="lead"
      />
    </Box>
    
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="flex-start" 
      sx={{ 
        flexDirection: 'column', 
        textAlign: 'left', 
        alignItems: 'left', 
        flex: '1', 
        paddingRight: '20px', 
        width: '100%' 
      }} 
      className={classes.rightGrid}
    >
      <Typography 
        variant="subtitle1" 
        color="primary" 
        ml={1}
        sx={{ 
          color: '#f9a400', 
          background: '#fef1d9', 
          borderRadius: '100px', 
          width: '300px', 
          alignItems: 'center', 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'space-around',
          marginLeft: { xs: '-10px', md: '-1px' }
        }}
        className={containerInView2 ? 'fade-in-up' : ''}
      >
        Used by 5000+ organisations<TrendingUpIcon />
      </Typography>
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom 
        sx={{ fontWeight: 'bold', fontSize: { xs: '24px', md: '36px' } }} 
        className={combinedClassName2}
      >
        Boost your sales & grow your revenue - FusionERP 
        <Typography component="span" sx={{ fontSize: { xs: '24px', md: '36px' }, color: '#f9a400', fontWeight: 'bold' }}>CRM</Typography>
      </Typography>
      <Typography className={combinedSubClassName2}>
        Time is a crucial element in our lives, helping us to structure and organize our daily activities into good habits...
      </Typography>



      <Box>
        <Grid sx={{ display: 'flex', background: 'none', flexDirection: 'column', justifyContent: 'space-around', margin: '20px 0', height: '180px' }}>
            <Box display="flex" alignItems="center" className={containerInView2 ? 'fade-in-up' : ''} style={{ animationDelay: '0.3s' }}>
              <Typography
                variant="body2"
                className='numbers-circle2'                >
                1
              </Typography>
              <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Comprehensive Lead Management</Typography>
            </Box>

            <Box display="flex" alignItems="center" className={containerInView2 ? 'fade-in-up' : ''} style={{ animationDelay: '0.4s' }} sx = {{marginTop:'20px'}}>
              <Typography
                variant="body2"
                className='numbers-circle2'
              >
                2
              </Typography>
              <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Multichannel Engagement</Typography>
            </Box>


            <Box display="flex" alignItems="center" className={containerInView2 ? 'fade-in-up' : ''} style={{ animationDelay: '0.4s' }} sx = {{marginTop:'20px'}}>
              <Typography
                variant="body2"
                className='numbers-circle2'
              >
                3
              </Typography>
              <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Drip Campaign and Notifications</Typography>
            </Box>
            <Box display="flex" alignItems="flex-start" className={containerInView2 ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx = {{marginTop:'20px'}}>
              <Typography
                variant="body2"
                className='numbers-circlepointeasily'
              >
                4
              </Typography>
              <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Easily Configurable Automation with 100+ Integrations</Typography>
            </Box>
        </Grid>

          <Box display="flex" alignItems="flex-start" gap={2}  sx = {{marginTop:'35px'}}>
            <Button variant="contained" color="primary" className={classes.signButton} 
            sx={{ color: '#fff', background: '#f9a400 !important',
              borderRadius:{
                xs : '18px'
              },
              '&:hover':{
                transform:'scale(0.9)'
              }
              }}>Start for FREE</Button>
            <Button variant="outlined" color="primary" className={classes.demoButton} 
              sx= {{ 
                
              color: '#f9a400', 
              background: '#fff', 
              border: '2px solid #f9a400', 
              borderRadius:{
                xs : '20px'
              },
                
              '&:hover': { 
                border: '2px solid #f9a400',
                transform:'scale(0.9)'
              
              } }} 
              href='/request-demo'
            >
              Book a Demo
            </Button>
          </Box>

      </Box>



      

        </Box>
      </Box>
    </Container>

      
      <Container 
         maxWidth="false"
         sx={{
          marginTop: '0 !important',
          minHeight: '90vh',
          paddingTop: '50px',
          width: '100%',
          '.MuiContainer-root': {
              maxWidth: 'none', 
          },
      }}
        ref={containerRef3}
      >
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width:'100%', padding: '0 10px' }}>
          <Box 
              display="flex" 
              alignItems="center" 
              justifyContent="space-between" 
              sx={{ flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', flex: '1', 
                    paddingRight: { md: '60px', lg: '80px' }, width: '100%' 
                  }}
            >
            <Typography variant="subtitle1" color="primary" ml={1}
              sx={{ color: '#008c44', background: '#d9eee3', 
                borderRadius: '100px', 
                width: '150px', 
                alignItems: 'center', 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-around',
                marginLeft: {
                  xs: '-10px',
                  md: '30px'
              }
              }}
              className={containerInView ? 'fade-in-up' : ''}
            >
              TRENDING<TrendingUpIcon />
            </Typography>
            <Typography variant="h3" component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold', 
              fontSize: { xs: '24px', md: '34px' },
              marginLeft: { xs: '0px', md: '35px' } 
          }}
          className={combinedClassName} >
              Optimize your entire business operations with - Fusion<Typography component="span" sx={{ fontSize: { xs: '24px', md: '34px' }, color: '#008c44', fontWeight: 'bold' }}>ERP</Typography>
            </Typography>
            <Typography className={combinedSubClassName}
                    sx={{ marginLeft: { xs: '0px', md: '35px' } }}
                >
              Get your business up and running in seconds with an efficient ERP system. OneHash ERP assists businesses by efficiently managing and organizing their everyday operations through HR, accounting,automation and more.
            </Typography>
            <Grid              
                    sx={{ 
                        display: 'flex', 
                        background: 'none', 
                        flexDirection: 'column',
                        height: { xs: '110px', md: '150px' }, 
                        justifyContent: 'space-around', 
                        marginTop: { xs: '20px', md: '20px' },
                        marginLeft: { xs: '0px', md: '35px' }, 
                        width: '100%', 
                    }}
                >
              <Box display="flex" alignItems="center" className={containerInView3 ? 'fade-in-up' : ''} style={{ animationDelay: '0.3s' }}>
                <Typography
                  variant="body2"
                  className='numbers-circle3'
                >
                  1
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>All in One Software</Typography>
              </Box>
              <Box display="flex" alignItems="center" className={containerInView3 ? 'fade-in-up' : ''} style={{ animationDelay: '0.4s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className='numbers-circle3'
                >
                  2
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Sales, Purchase and Quality Control</Typography>
              </Box>
              <Box display="flex" alignItems="center" className={containerInView3 ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className='numbers-circle3'
                >
                  3
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Workflow Automation and Approval setup</Typography>
              </Box>

              <Box display="flex" alignItems="center" className={containerInView3 ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className='numbers-circle3'
                >
                  4
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Powerful Dashboard and Reports</Typography>
              </Box>

            </Grid>
            <Box display="flex" 
              alignItems="flex-start" gap={2} className={classes.boxButtons} 
              sx={{ pt: { xs: 13, sm: 12, md: 3 } ,
                    ml : {xs:0,sm:0,md:5}
                  }}
              >
                <Button className={classes.signButton} 
                    sx={{ 
                        color: '#fff', 
                        background: '#008c44 !important',
                        borderRadius: {
                            xs: '18px'
                        },
                        '&:hover':{
                          transform:'scale(0.9)'
                        }
                    }} 
                >
                    Start for FREE
                </Button>
                <Button className={classes.demoButton} href='/request-demo' sx={{ border: '2px solid #008c44 !important',
                    color:'#008c44 !important',
                    borderRadius: {
                        xs: '20px'
                    },
                    '&:hover':{
                      transform:'scale(0.9)'
                    }
                }} 
                >
                    Book a Demo
                </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0 35px' }} className={classes.leftImage}>

            <img src= {erpimages}
              style={{ maxWidth: '100%' ,maxHeight:'100%'}}
              className={containerInView3 ? 'scale-in' : ''}
              alt = "erpimages"
            />


          </Box>
        </Box>

      </Container>

      <Container 
        maxWidth={false}

        sx={{
          marginTop: '0 !important',
          minHeight: '90vh',
          paddingTop: '90px',
          width: '100%',   
          maxWidth: 'none',
          '.MuiContainer-root': {
            maxWidth: 'none'
          }
        }}
        ref={containerRef4}

      >
        <Box sx={{ display: 'flex', 
              flexDirection: { xs: 'column-reverse', md: 'row' }, 
              width: '100%', padding: '0 10px' }}
        >
        <Box       
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
          }} 
          className={classes.leftImage}
        >
          <img 
            src='https://www.manageengine.com/analytics-plus/images/sdp-pages/sdp-asset-banner.svg'
            style={{ maxWidth: '85%' }}
            className={containerInView4 ? 'scale-in' : ''}
            alt="manageegine"
          />
        </Box>

      
      
      <Box       display="flex" 
      alignItems="center" 
      justifyContent="flex-start" 
      sx={{ 
        flexDirection: 'column', 
        textAlign: 'left', 
        alignItems: 'left', 
        flex: '1', 
        paddingRight: '20px', 
        width: '100%' 
      }} 
      className={classes.rightGrid}>
            <Typography variant="subtitle1" color="primary" ml={1}
              sx={{ 
                color: '#0080e5',
                 background: '#e6f4ff', 
                 borderRadius: '100px', 
                 width: '150px', 
                 alignItems: 'center', 
                 display: 'flex', 
                 flexDirection: 'row', 
                 justifyContent: 'space-around',
                
               }}
               className={containerInView2 ? 'fade-in-up' : ''}
            >
              TRENDING<TrendingUpIcon />
            </Typography>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', marginBottom: '0 !important', fontSize: { xs: '24px', md: '34px' }, }} className={combinedClassName4} >
              Only Scheduling tool you need - FusionERP <Typography component="span" sx={{ fontSize: { xs: '24px', md: '34px' }, color: '#0080e5', fontWeight: 'bold', }}>Asset Management</Typography>
            </Typography>
            <Typography className={combinedSubClassName4}>
              Helps businesses efficiently manage and maintain their assets, ensuring optimal utilization and minimizing downtime.            </Typography>
            
              <Grid sx={{ display: 'flex', background: 'none', flexDirection: 'column', justifyContent: 'space-around', margin: '20px 0', height: '180px' }}>
              <Box display="flex" alignItems="center" className={containerInView4 ? 'fade-in-up' : ''} style={{ animationDelay: '0.3s' }}>
                <Typography variant="body2" className="numbers-circle4" >
                  1
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Convertibility</Typography>
              </Box>
              <Box display="flex" alignItems="center" className={containerInView4 ? 'fade-in-up' : ''} style={{ animationDelay: '0.4s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className="numbers-circle4"

                >
                  2
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Physical Existence</Typography>
              </Box>
              <Box display="flex" alignItems="center" className={containerInView4 ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className="numbers-circle4"
                >
                  3
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Asset Transactions</Typography>
              </Box>
              <Box display="flex" alignItems="center" className={containerInView4 ? 'fade-in-up' : ''} style={{ animationDelay: '0.5s' }} sx = {{marginTop:'20px'}}>
                <Typography
                  variant="body2"
                  className="numbers-circle4"
                >
                  4
                </Typography>
                <Typography variant="body1" ml={1} sx={{ fontSize: { xs: '14px', md: '20px' }, fontWeight: 400, fontFamily: 'poppins, sans-serif' }}>Asset Reports and Dashboards</Typography>
              </Box>
            </Grid>
            <Box display="flex"  alignItems="flex-start" gap={2} className={classes.boxButtons} sx={{ pt: { xs: 2, sm: 2, md: 2 } }}>
              <Button className={classes.signButton} 
                    sx={{ 
                        color: '#fff', 
                        background: '#0080e5',
                        borderRadius: {
                            xs: '18px'
                        },
                        '&:hover':{
                          transform:'scale(0.9)'
                        }
                    }} 
                >
                    Start for FREE
                </Button>
                <Button className={classes.demoButton} href='/request-demo' sx={{ border: '2px solid #0080e5',
                    borderRadius: {
                        xs: '20px'
                    },
                    '&:hover':{
                      transform:'scale(0.9)'
                    }
                }} 
                >
                    Book a Demo
                </Button>
            </Box>
          </Box>

        </Box>

      </Container>

      

        {/* JUST Fusion ERP */}
        <Container
          sx={{
            marginTop: '0 !important',
            minHeight: '50vh', 
            height: 'auto',
            paddingTop: { xs: '0px', md: '10px', lg: '10px' },
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            margin: { xs: '0 auto', md: '0 auto !important' },
            width:'1240px !important',
            maxWidth:'100%',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={containerRefHero}
        >
          <HeroContainer
            sx={{
              backgroundImage: 'url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4399efb600282b4f_Star.svg),url(https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96d4bcb5f3b507ba65d_Circle.svg)',
              backgroundPosition: '92% 100%,6% 0',
              backgroundRepeat: 'no-repeat !important',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '0px 10px', md: '0px' },
              minHeight: '55vh',
              height: 'auto',
              width: '100%',
              gridRowGap: { xs: '10px', md: '25px', lg: '30px' },
              maxWidth: { xs: '80%', sm: '50%', md: '100%', lg: '100%' }
            }}
            className={containerHero ? "hero-container" : ""}
          >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              paddingTop:"30px",
              fontSize: { xs: '24px', md: '40px' },
              fontWeight: {xs : 400,md:600},
              margin: { xs: '10px auto', md: '10px auto' },
            }}
            className={containerHero ? "hero-title" : ""}
          >
            Just FusionERP It !!
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: { xs: '14px', md: '16px' }, color: '#cdd5df' }}
            className={containerHero ? "hero-subtitle" : ""}
          >
            Trust in the Power of FusionERP
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            pt = {2}
            mt={3}
            sx={{
              margin: { xs: '1px auto', md: '1px auto' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
            className={containerHero ? "hero-buttons" : ""}
          >
            <HeroButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#fff',
                color: '#007bff',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
            >
              Start for FREE
            </HeroButton>
            <HeroButton
              variant="button"
              color="inherit"
              sx={{
                border: '2px solid #fff  !important',
                width: { xs: '100%', md: 'auto' },
                padding:'16px 36px',
                fontSize:'16px',
                fontWeight:500,
                display : 'flex'
              }}
              href='/request-demo'
            >
              Book a Demo
            </HeroButton>
          </Box>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={{xs : 2,sm:3,md:6,lg:6}} 
              mt={3}
              sx={{
                flexDirection: {xs: 'column',sm:'column',md:'row',lg:'row'},
                textAlign: 'center', 
                margin: '0 auto',
                width: '100%',
                maxWidth: '95%',
              }}
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Free 15 days trial
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '45px',
                    sm : '40px',
                    md : '0px',
                    lg : '0px'
                  
                  }

                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                No credit card required
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: 1.5,
                  marginLeft:{
                    xs : '-12px',
                    sm : '-24px',
                    md : '0px',
                    lg : '0px'
                  }
                }}
              >
                <img
                  src="https://cdn.prod.website-files.com/5e53d34464688e6f5960a338/65d0f96de366dcfc3c865f78_correct%201.svg"
                  loading="lazy"
                  alt=""
                  style={{ marginRight: '5px', width: '24px', height: '24px' }}
                />
                Support 24/7
              </Typography>
            </Box>
          </HeroContainer>
        </Container>


        <Container
                sx={{
                marginTop: {
                    xs: '10%',  
                    sm: '12%', 
                    md: '15%', 
                    lg: '5%'
                },
                maxWidth: '100%' 
                }}
            >
                <Grid
                container
                sx={{
                    marginTop: {
                    xs: '-50px',  
                    sm: '-70px',  
                    md: '-30px',  
                    lg: '-50px' 
                    },
                    justifyContent: 'center' 
                }}
                >
                <Footer />
                </Grid>
        </Container>

    </React.Fragment >
  );
};

export default LandingPage;
